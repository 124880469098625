<template>
    <div class="noti-div">
        <div class="top-heading mb-5"><a href="/">
            <svg width="34" height="8" viewBox="0 0 34 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35356L3.82843 7.53554C4.02369 7.7308 4.34027 7.7308 4.53553 7.53554C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17158C4.7308 0.976313 4.7308 0.659731 4.53553 0.464469C4.34027 0.269207 4.02369 0.269207 3.82843 0.464469L0.646447 3.64645ZM34 3.5L1 3.5L1 4.5L34 4.5L34 3.5Z" fill="#BB935A"/>
            </svg>
            </a> QR Scanner
        </div>
        <qrcode-stream @decode="onDecode"></qrcode-stream>
    </div>
</template>

<script>
import QrcodeStream from 'vue-qrcode-reader/src/components/QrcodeStream'

    export default {
        props: [],
        data() {
            return {
                isloaded: true,
            }
        },
        components: {
            QrcodeStream,
        },
        mounted() {
        },
        methods: {
            onDecode: function(decodedString) {
                window.location.href = decodedString;
            }
        }
    }
</script>