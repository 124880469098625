<template>
    <div class="address-panel">
        <div v-if="!isloaded" id="cover-spin"></div>
        <!-- Open Addresses -->
        <div class="closebtn"><span><a :href="prevpage">
            <svg width="34" height="8" viewBox="0 0 34 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35356L3.82843 7.53554C4.02369 7.7308 4.34027 7.7308 4.53553 7.53554C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17158C4.7308 0.976313 4.7308 0.659731 4.53553 0.464469C4.34027 0.269207 4.02369 0.269207 3.82843 0.464469L0.646447 3.64645ZM34 3.5L1 3.5L1 4.5L34 4.5L34 3.5Z" fill="#BB935A"/>
            </svg>
        </a></span> Addresses</div>
        <div id="dashboard-rows p-0">
            <div v-for="(address, index) in user_addresses" :key="index" class="dashboard-row" @click="highlighted_address = index"
                :class="{'highlighted': index == highlighted_address}">
                <div>
                    <p v-if="address.distance > max_distance" style="color:red;">⚠️ This address is out of delivery range.</p>
                    <p v-if="address.unit_number">{{address.unit_number}}</p>
                    <p class="m-0">{{address.street1}}</p>
                    <p class="m-0">{{address.postcode}}</p>
                    <p class="m-0">{{address.city}}</p>
                    <p class="m-0">{{address.state}}</p>
                </div>
                <div class="ml-auto px-2"><a :href="'/addresses/'+address.id+'/edit'">
                    <svg width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.84042 3.24301L1.98458 9.09692L1.60167 10.4027L2.8813 10.0328L8.75657 4.15951L7.84042 3.24301ZM8.8395 2.24426L9.75565 3.1601L10.6096 2.30644C10.6703 2.24571 10.7045 2.16335 10.7045 2.07747C10.7045 1.9916 10.6703 1.90924 10.6096 1.84851L10.1509 1.39059C10.0901 1.32988 10.0077 1.29577 9.92184 1.29577C9.83594 1.29577 9.75355 1.32988 9.6928 1.39059L8.84015 2.24426H8.8395ZM11.0677 0.474742L11.5258 0.932666C11.8294 1.23632 12 1.64811 12 2.07747C12 2.50684 11.8294 2.91863 11.5258 3.22228L3.5616 11.1845L0.827412 11.9746C0.715963 12.0068 0.597932 12.0084 0.48564 11.9794C0.373349 11.9503 0.270914 11.8917 0.18903 11.8096C0.107147 11.7274 0.0488165 11.6248 0.0201294 11.5125C-0.00855772 11.4001 -0.00654988 11.2822 0.0259434 11.1709L0.834539 8.41489L8.77795 0.474094C9.0817 0.170532 9.49363 0 9.92314 0C10.3526 0 10.7646 0.170532 11.0683 0.474094L11.0677 0.474742Z" fill="#ECBC7B"/>
                    </svg>
                </a></div>
                <div><a data-confirm="Are you sure?" rel="nofollow" data-method="delete" :href="'/addresses/'+address.id">
                    <svg width="20" height="20" viewBox="0 0 16 16" class="bi bi-x" fill="red" stroke="red" stroke-width="2" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"/>
                        <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"/>
                    </svg>
                </a></div>
            </div>
            <div @click="opendLoc()" class="dashboard-row font-weight-bold border-0" style="color:#BB935A;"> + Add an Address </div>
        </div>
        <button v-if="user_addresses.length" @click="submitForm" class="submit-btn default-btn">Choose as Default</button>
        <get-location @sentaddress="getAddress"></get-location>
        <form v-if="user_addresses.length" id="addressForm" :action="'/addresses/'+user_addresses[highlighted_address].id" accept-charset="UTF-8" method="post" class="d-none">
            <input type="hidden" name="_method" value="patch">
            <input type="hidden" name="authenticity_token" :value="auth_token"> 
            <input type="hidden" name="address[is_default]" :value="true">
        </form>
    </div>
</template>

<script>
    import GetLocation from './getLocation.vue'

    export default {
        components: {
            GetLocation,
        },
        props: ['auth_token', 'current_user', 'user_addresses', 'max_distance'],
        data() {
            var index = 0;
            for (var i = 0; i < this.user_addresses.length; i++) {
                if (this.user_addresses[i].is_default) {
                    index = i;
                }
            }

            return {
                highlighted_address: index,
                prevpage: '/dashboard#profile',
                isloaded: true,
            }
        },
        methods: {
            opendLoc: function () { document.getElementById("locPopUp").style.marginLeft = "0" },
            closedLoc: function () { document.getElementById("locPopUp").style.marginLeft = "-100%" },
            getAddress: function (evt, address, city, state, postcode, coords) {
                let vm = this;
                this.$http
                    .post("/addresses", {
                        authenticity_token: vm.auth_token,
                        address: {
                            street1: address,
                            city: city,
                            state: state,
                            postcode: postcode,
                            latitude: coords.lat,
                            longitude: coords.lng,
                            customer_id: vm.current_user.id
                        },
                    })
                    .then(response => {
                        //console.log(response);
                        //console.log('Added Address')
                        window.location.href = response.request.responseURL + '/edit';
                    })
                    .catch(error => {
                        if (error.response) {
                            //console.log(error.response)
                        } else {
                            //console.log('Add Address Error')
                        }
                    });
            },
            submitForm: function() {
                let vm = this;
                vm.isloaded = false;
                var url = "/addresses/"+ vm.user_addresses[vm.highlighted_address].id + '.json';
                this.$http
                    .patch(url, {
                        authenticity_token: vm.auth_token,
                        address: {
                            is_default: true,
                        },
                    })
                    .then(response => {
                        window.location.href = vm.prevpage;
                    })
                    .catch(error => {
                        window.location.href = vm.prevpage;
                    });
                //document.getElementById('addressForm').submit();
            }
        },
        mounted() {
            //console.log(this.current_user)
            //console.log(this.user_addresses)
            //console.log(document.referrer)

            const prev = (document.referrer).split('/')[(document.referrer).split('/').length - 1]
            //console.log(prev)

            if (typeof(Storage) !== "undefined") {
                if (prev == 'cart' || prev == 'dashboard#profile') {
                    // Store
                    localStorage.setItem("lastpage", document.referrer);
                } else if (prev == 'dashboard') {
                    // Store
                    var url = document.referrer + '#profile';
                    localStorage.setItem("lastpage", url);
                }

                this.prevpage = localStorage.getItem("lastpage");

            } else {
                this.prevpage = '/dashboard#profile';
            }
        }
    }
</script>

<style scoped>
.address-panel {
    height: 100vh;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    position: relative;
    background-color: white;
    overflow-x: hidden;
    transition: 0.5s;
}

.address-panel .closebtn {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 0;
    z-index: 3;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    height: 80px;
    padding: 30px 20px 20px;
    background: #FFF4E4;
    text-align: left;
    border: none;
    color: #BB935A;
    letter-spacing: 0.03em;
}

.address-panel .closebtn:focus {
    outline: none;
}

.address-panel .closebtn span {
    font-size:24px;
    margin-right:10px;
    cursor: pointer;
}

.highlighted {
    background: #FBE2C0;
}

.address-panel .dashboard-row {
    padding: 15px 15px 15px 25px;
}

.default-btn {
    position: sticky;
    position: -webkit-sticky;
    bottom: 15px;
    left: 0;
    margin: 35px 25px 15px !important;
    width: calc(100% - 50px) !important;
}
</style>