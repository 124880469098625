<template>
  <div class="text-center">
    <h4>
      {{ message }}
    </h4>
  </div>
</template>

<script>

export default {
  name: 'notification',
  data: function () {    
    return {      
      message: 'Waiting for messages...'
    }  }
  ,  
  created: function () {    
    const vm = this;
    this.$cable.subscriptions.create(
      { 
        channel: 'NotificationChannel' 
      },      
      {        
        received: function (data) {          
          vm.message = data.body;    
        }      
      }    
    );  
  } 
}
</script>
