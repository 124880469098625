
<template>
  <swiper v-if="isloaded && rows.subcategories.length" class="swiper" :options="swiperOption">
    <swiper-slide v-for="(row, index) in rows.subcategories" :key="index">
      <a :href="'/categories/'+row.id">
        <div v-if="row.subcategory_image.url" class="d-flex justify-content-center"><img :src="row.subcategory_image.url" class="category-icons"></div>
        <div v-else class="d-flex justify-content-center"><img src='/images/kozi-exsm-sq.png' class="category-icons"></div>
        <div class="category-text">{{row.name}}</div>
      </a>
    </swiper-slide>
    <div class="swiper-pagination" :class="{'d-none': rows.subcategories.length <= 4}" slot="pagination"></div>
  </swiper>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'

  export default {
    name: 'swiper-example-slides-per-column',
    title: 'Multi row slides layout',
    components: {
      Swiper,
      SwiperSlide
    },
    props: ['category'],
    data() {
      return {
        swiperOption: {},
        isloaded: false,
        rows: this.category || [],
      }
    },
    watch: {
      category(val) {
        this.rows = val
      }
    },
    computed: {
      width() {
        return window.innerWidth 
            || document.documentElement.clientWidth 
            || document.body.clientWidth 
            || 0;
      },
      height() {
        return window.innerHeight 
            || document.documentElement.clientHeight 
            || document.body.clientHeight 
            || 0;
      }
    },
    mounted() {
      //console.log( this.width)
      if (this.width >= 768) {
        this.swiperOption = {
          slidesPerView: 8,
          slidesPerColumn: 1,
          spaceBetween: 10,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        };
        this.isloaded = true;
      } else {
        this.swiperOption = {
          slidesPerView: 4,
          slidesPerColumn: 1,
          spaceBetween: 10,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        };
        this.isloaded = true;
      }
    },
  }
</script>

<style scoped>

  .category-icons {
    height: 40px;
    width: 40px;

  }

  .category-text {
    text-align: center;
    line-height: 14px;
    font-size: 12px;
    margin-top: 5px;
  }

  .swiper {
    height: 90px;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper .swiper-slide {
    height: 60px;
  }

  @media (min-width: 768px) {
    .swiper-pagination {
      display: none !important;
    }

    .swiper-container {
      padding: 20px 0;
    }

    .swiper {
      height: 100px;
    }
  }

</style>