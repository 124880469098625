<template>
    <div id="slide-div" class="product-slide">
        <div class="slide-img-div">
            <a :href="back_btn" class="slide-xbtn">✕</a>
            <swiper :options="swiperOptions" class="slide-img">
                <swiper-slide v-if="featured_photo.url"><img :src="featured_photo.url" alt='slide-img' class="swiper-img"></swiper-slide>
                <swiper-slide v-else><img src='/images/placeholder.png' alt='slide-img' class="swiper-img"></swiper-slide>
                <swiper-slide v-for="(row, index) in gallery_photos" :key="index">
                    <img :src="row.file.url" class="swiper-img">
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>
        <div class="slide-details-div">
            <div class="row m-0">
                <div class="col-8 p-0">
                    <div class="slide-title">{{ product.name }}</div>
                    <div class="slide-subtitle mt-2">by <a :href="'/stores/'+store.permalink" style="color: #BB935A;font-weight:bold;">{{ store.name }}</a></div> 
                </div>
                <div class="col-4 p-0 text-right">
                    <div v-if="product.sales_price_cents != product.original_price_cents && product.sales_price_cents" class="discounted-price">{{ (product.sales_price_cents/100).toFixed(2) }}</div>
                    <div class="slide-title" :class="{'slash-price':product.sales_price_cents != product.original_price_cents && product.sales_price_cents}" >
                        {{ product.original_price_currency }} 
                        {{ (product.original_price_cents/100).toFixed(2) }}
                    </div>
                    <div class="slide-subtitle">base price</div>
                </div>
            </div>
            <div class="mt-4"><desc-show>{{ product.description }}</desc-show></div>
        </div>

        <div v-if="store.category.name == 'Buy' || store.category.name == 'Eat'">
            <div v-if="variants.length != 0" class="slide-choice-div">
                <div class="slide-sect-title">Product Variants</div>
                <div v-for="(variant, index) in variants" :key="index" class="d-flex flex-nowrap align-items-center">
                    <input v-if="variant.is_available" class="mr-3" type="radio" v-model="variation" :value="variant.id" name="variation" :id="'var'+index" :checked="index == 0">
                    <input v-else class="mr-3" type="radio" v-model="variation" :value="variant.id" name="variation" :id="'var'+index" :checked="index == 0" disabled>
                    <label v-if="variant.is_available" class="slide-choice" :for="'var'+index">{{ variant.name }} <span class="ml-auto">+ {{(variant.add_on_price_cents/100).toFixed(2)}}</span></label>
                    <label v-else class="slide-choice" :for="'var'+index" style="color:#A9A9A9;">{{ variant.name }} <span class="ml-auto">+ {{(variant.add_on_price_cents/100).toFixed(2)}}</span></label>
                </div>
            </div>
            <div v-if="addons.length != 0" class="slide-choice-div" style="padding-bottom:30px;border-top:none;">
                <div class="slide-sect-title">Add Ons</div>
                <div v-for="(addon, index) in addons" :key="index" class="d-flex flex-nowrap align-items-center">
                    <input v-if="addon.is_available" class="mr-3" type="checkbox" v-model="addedons" :value="addon.id" name="addedons" :id="'addon'+index">
                    <input v-else class="mr-3" type="checkbox" v-model="addedons" :value="addon.id" name="addedons" :id="'addon'+index" disabled>
                    <label v-if="addon.is_available" class="slide-choice" :for="'addon'+index">{{ addon.name }} <span class="ml-auto">+ {{(addon.price_cents/100).toFixed(2)}}</span></label>
                    <label v-else class="slide-choice" :for="'addon'+index" style="color:#A9A9A9;">{{ addon.name }} <span class="ml-auto">+ {{(addon.price_cents/100).toFixed(2)}}</span></label>
                </div>
            </div>
        </div>

        <div v-if="store.category.name == 'Fix' || store.category.name == 'Play'" class="slide-choice-div">
            <div class="slide-sect-title">Select Appointment Slot</div>
            <div class="row pt-3">
                <!-- <div class="col pr-2 select-one-date">
                    <md-datepicker v-model="fixDate" :md-disabled-dates="disabledDates">
                        <label>Appointment Date</label>
                        <span v-if="fixDay" class="date-day">{{days[fixDay]}}</span>
                    </md-datepicker>
                </div> -->
                <div class="col pr-2">
                    <md-field>
                        <label for="fixdate">Appointment Date</label>
                        <md-select v-model="fixDate" name="fixslot" id="fixdate" style="padding-left:12px;">
                            <md-option v-for="(slot, index) in dateslots" :value="slot" :key="index">
                                {{ slot }}
                            </md-option>
                        </md-select>
                    </md-field>
                </div>
                <div class="col pl-2">
                    <md-field>
                        <label for="fixslot">Appointment Slot</label>
                        <md-select v-model="fixSlot" name="fixslot" id="fixslot" style="padding-left:12px;">
                            <md-option v-for="(slot, index) in timeslots" :value="slot.id" :key="index">
                                {{ slot.start_time }} - {{ slot.end_time }}
                            </md-option>
                        </md-select>
                    </md-field>
                </div>
            </div>
        </div>

        <div v-if="store.category.name == 'Stay' && checkinDate && checkoutDate" class="slide-choice-div">
            <!-- <div class="slide-sect-title">Select Stay Duration</div>
            <div class="w-100 hotel-datepicker">
                <md-datepicker v-model="checkinDate" :md-disabled-dates="disabledcheckinDates">
                    <label>Check in Date</label>
                    <span v-if="checkinDay" class="date-day">{{days[checkinDay]}}</span>
                </md-datepicker>
            </div>
            <div class="w-100 hotel-datepicker">
                <md-datepicker v-model="checkoutDate" :md-disabled-dates="disabledcheckoutDates">
                    <label>Check out Date</label>
                    <span v-if="checkoutDay" class="date-day">{{days[checkoutDay]}}</span>
                </md-datepicker>
            </div> -->
            <div class="slide-sect-title">Stay Duration</div>
            <div>Check in: {{ formatDate(checkinDate) }}</div>
            <div>Check out: {{ formatDate(checkoutDate) }}</div>
            <div style="color:black">Only <b>{{ product.available_unit }}</b> rooms left. </div>
        </div>

        <div v-if="store.category.name == 'Stay' && checkinDate && checkoutDate" class="slide-request-div border-bottom">
            <div class="slide-sect-title">Guest Information</div>
            <div class="d-flex mb-2"><label class=" col-4" for="title">Title: </label><select id="first-name" v-model="cus_title"><option>Mr</option><option>Ms</option></select></div>
            <div class="d-flex mb-2"><label class=" col-4" for="first-name">First Name: </label><input id="first-name" v-model="cus_fname"></div>
            <div class="d-flex mb-2"><label class=" col-4" for="last-name">Last Name: </label><input id="last-name" v-model="cus_lname"></div>
            <div class="d-flex mb-2"><label class=" col-4" for="email">Email: </label><input id="email" v-model="cus_email"></div>
            <div class="d-flex mb-2"><label class=" col-4" for="phone">Phone Number: </label><input id="phone" v-model="cus_phone"></div>
        </div>
        <div class="slide-request-div">
            <div class="slide-sect-title">Special Request <span style="color: #848484;font-weight:normal;margin-left:10px;">Optional</span></div>
            <textarea v-model="request" class="request-input" placeholder="Specify your request here"></textarea>
        </div>
        <div class="slide-count-div">
            <button id="minus-btn" @click="editCount('MINUS')" class="count-btn">-</button>
            <div class="count">{{count}}</div>
            <button @click="editCount('PLUS')" class="count-btn">+</button>
        </div>
        <div class="cart-btn-div d-flex justify-content-center">
            <button v-if="product.active" @click="addCart()" class="cart-btn">Add to Carts</button>
            <button v-else class="cart-btn" disabled style="background:#9E9E9E;">This product is unavailable currently</button>
        </div>

        <md-dialog :md-active.sync="confirmcart">
            <button class="sign-in-xbtn" @click="confirmcart = false">✕</button>
            <md-dialog-title style="text-align:center;color:#BB935A;font-size:18px;">Start new shopping cart?</md-dialog-title>
            <div style="padding:0 32px 15px;">
                <div class="text-center">Adding this item to carts will clear your current item in your shopping carts</div>
                <button @click="handleConfirm()" class="submit-btn">Proceed</button>
                <button @click="confirmcart = false" class="submit-btn-reverse">Cancel</button>
            </div>
        </md-dialog>

        <md-dialog :md-active.sync="goSelectdate">
            <button class="sign-in-xbtn" @click="goSelectdate = false">✕</button>
            <md-dialog-title style="text-align:center;color:#BB935A;font-size:18px;">Please Select Your Stay Duration</md-dialog-title>
            <div style="padding:0 32px 15px;">
                <div class="text-center">You have not selected your stay duration. Please go back to the store page to select.</div>
                <button @click="gotoStore()" class="submit-btn">Go To Store</button>
            </div>
        </md-dialog>

        <md-dialog :md-active.sync="showDialog">
            <button class="sign-in-xbtn" @click="showDialog = false">✕</button>
            <div id="login-dialog">
                <md-tabs md-active-tab="login-tab" md-alignment="fixed">
                    <md-tab id="register-tab" md-label="Register">
                        <reg-form :authToken="auth_token"></reg-form>
                    </md-tab> 

                    <md-tab id="login-tab" md-label="Login">
                        <login-form :authToken="auth_token"></login-form>
                    </md-tab>
                </md-tabs>
            </div> 
        </md-dialog>
    </div>
</template>

<script>
import RegForm from './regForm.vue';
import LoginForm from './loginForm.vue';

export default {
    name: 'product-slide',
    props: ['product', 'addons','variants','featured_photo','gallery_photos','store','store_categories', 'cart_items', 'auth_token', 'current_user'],
    components: {
    },
    data() {
        let dateFormat = 'dd-MM-yyyy';
        var catshow = '';
        for (var j = 0; j < this.store_categories.length; j++) { if (this.store_categories[j].ancestry == null) { catshow = this.store_categories[j].name } }
        var vart;
        var vart_ava = false;
        if (this.variants.length) { 
            if (this.variants[0].is_available) { 
                vart = this.variants[0].id 
            } 
            for (var k = 0; k < this.variants.length; k++) { 
                if (this.variants[k].is_available) { 
                    vart_ava = true;
                } 
            }
        }
        else { vart = null }

        var mc = 0;
        if (this.product.inventory_count) {
            mc = this.product.inventory_count;
        } else if (this.product.available_unit) {
            mc = this.product.available_unit;
        }

        if (this.current_user) {
            var cfname = this.current_user.first_name;
            var clname = this.current_user.last_name;
            var cemail = this.current_user.email;
            var cphone = this.current_user.phone_number;
        }

        const prev = document.referrer

        return{
            swiperOptions: {
                direction: 'horizontal',
                slidesPerView: 1,
                spaceBetween: 0,
                mousewheel: true,
                loop: false,
                initialSlide: 0,
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: true,
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                //breakpoints: {},
            },
            back_btn: prev,
            variation: vart,
            available_var: vart_ava,
            addedons: [],
            appointment_slots: [],
            fixDate: null,
            fixDay: null,
            fixSlot: null,
            days: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
            checkinDate: null,
            checkinDay: null,
            checkoutDate: null,
            checkoutDay: null,
            disabledcheckinDates: date => {
                return date.getTime() < (Date.now() - 86400000);
            },
            disabledcheckoutDates: date => {
                return date.getTime() < Date.now();
            },
            disabledDates: date => {
                return date.getTime() < (Date.now() - 86400000);
            },
            request: '',
            count: 1,
            confirmcart: false,
            maxcount: mc,
            goSelectdate: false,
            cus_title: 'Mr',
            cus_fname: cfname || null,
            cus_lname: clname || null,
            cus_email: cemail || null,
            cus_phone: cphone || null,
            showDialog: false,
        }
    },
    watch: {
        variation(val) {
            //console.log(val)
        },
        addedons(val) {
            //console.log(val)
        },
        count(val) {
            if (val >= 2) {document.getElementById("minus-btn").style.color = "#373737"}
            else {document.getElementById("minus-btn").style.color = "#C2C2C2"}
        },
        fixDate(val) {
            //this.fixDay = val.getDay();
            this.fixSlot = null;
        },
        checkinDate(val) {
            //console.log(val)
            this.checkinDay = val.getDay();
            this.disabledcheckoutDates = date => {
                return date.getTime() < (Number(val) + 86400000);
            };
        },
        checkoutDate(val) {
            //console.log(val)
            this.checkoutDay = val.getDay();
            this.disabledcheckinDates = date => {
                return date.getTime() > (Number(val) - 86400000) || date.getTime() < (Date.now() - 86400000);
            };
        },
        fixSlot(val) {
            if (val) {
                var inv = this.timeslots.filter(function(u) {
                    return u.id == val;
                })
                this.maxcount = inv[0].available_slot;
            }
        }
    },
    mounted() {
        this.$material.locale.dateFormat = 'dd/MM/yyyy';
        //console.log(this.product)
        //console.log(this.addons)
        //console.log(this.variants)
        //console.log(this.featured_photo)
        //console.log(this.gallery_photos)
        // console.log(this.store)
        //console.log(this.store_categories)
        //console.log(this.cart_items)
        //console.log(this.current_user)

        const urlParams = new URLSearchParams(window.location.search);
		var inParam = urlParams.get('checkin_date')
        var outParam = urlParams.get('checkout_date')
        if (inParam) { this.checkinDate = new Date(inParam) }
        if (outParam) { this.checkoutDate = new Date(outParam) }

        this.$http
            .get(window.location.href +'/appointment_slots', {
                authenticity_token: this.auth_token,
            })
            .then(response => {
                this.appointment_slots = response.data.data.appointment_slots;
                //console.log(response.data.data.appointment_slots)
                this.isloaded = true;
            })
            .catch(error => {
                //console.log(error);
                this.isloaded = true;
                this.error = true;
            });
    },
    methods: {
        gotoStore: function() {
            window.location.href = '/stores/'+this.store.permalink;
        },
        parseTime: function(str) {
            if (str) {
                var res = str.split("T")[1].split(":");
                var ap = 'AM';
                if (parseInt(res[0]) > 12) {
                    res[0] = parseInt(res[0]) - 12;
                    if (res[0] < 10) {res[0] = '0' + res[0]}
                    ap = 'PM';
                }
                var time = res[0] + '.' + res[1] + ap;

                return time;
            } else {
                return '';
            }
        },
        editCount: function(i) {
            let vm = this;
            if (i == 'PLUS') {
                if (vm.count == vm.maxcount) {
                    alert('You have reach the maximum inventory count.')
                } else {
                    vm.count = vm.count + 1
                }
            }

            if (i == 'MINUS') {
                if (vm.count >= 2) {
                    vm.count = vm.count - 1;
                }
            }
        },
        formatDate: function(d) {
            var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            var strday = days[d.getDay()];
            var month = months[d.getMonth()];
            var day = '' + d.getDate();
            var year = d.getFullYear();

            if (day.length < 2) 
                day = '0' + day;

            return [strday, day, month, year].join(' ');
        },
        addCart: function() {
            if (!this.current_user) {
                this.showDialog = true; // login before proceed
            } else if (this.cart_items.store_id == null || this.cart_items.store_id == this.store.id || !this.cart_items.cart_items.length) {
                if (this.appointment_slots.length || this.store.category.name == 'Play' || this.store.category.name == 'Fix') {
                    if (this.fixDate == null || this.fixSlot == null) {
                        alert('Please select a time slot.')
                    } else {
                        this.handleConfirm();
                    }
                } else if (this.available_var && !this.variation) {
                    alert('Please choose a variation.')
                } else if (this.store.category.name == 'Stay' && !this.checkinDate) {
                    this.goSelectdate = true;
                } else if (this.store.category.name == 'Stay' && (!this.cus_fname || !this.cus_lname || !this.cus_email || !this.cus_phone)) {
                    alert('Please enter the guest details.')
                } else {
                    this.handleConfirm();
                }
            } else {
                if (this.appointment_slots.length) {
                    if (this.fixDate == null || this.fixSlot == null) {
                        alert('Please select a time slot.')
                    } else {
                        this.confirmcart = true;
                    }
                } else if (this.available_var && !this.variation) {
                    alert('Please choose a variation.')
                } else if (this.store.category.name == 'Stay' && !this.checkinDate) {
                    this.goSelectdate = true;
                } else if (this.store.category.name == 'Stay' && (!this.cus_fname || !this.cus_lname || !this.cus_email || !this.cus_phone)) {
                    alert('Please enter the guest details.')
                } else {
                    this.confirmcart = true;
                }
            }
        },
        handleConfirm: function() {
            let vm = this;
            var addition = [];
            var req = vm.request;
            if (this.store.category.name == 'Stay') {
                req = req + '<Guest Details>' + vm.cus_title + '<AND>' + vm.cus_fname + '<AND>' + vm.cus_lname + '<AND>' + vm.cus_email + '<AND>' + vm.cus_phone;
            }
            for (var k = 0; k < vm.addedons.length; k++) {
                addition[k] = { product_addon_id: vm.addedons[k] }
            }
            vm.confirmcart = false;
            if (vm.fixSlot) { // Play & Fix
                this.$http
                    .post("/cart", { 
                        authenticity_token: vm.auth_token,
                        cart_item: {
                            product_id: vm.product.id,
                            quantity: parseInt(vm.count),
                            remark: req,
                            appointment_attributes: { slot_id: vm.fixSlot }
                        },
                    })
                    .then(response => {
                        //console.log(response.data);
                        //console.log('Added to Cart');
                        window.location.href = '/stores/' + vm.store.permalink;
                    })
                    .catch(error => {
                        if (error.response) {
                            //console.log(error.response)
                        }else{
                            //console.log('Add Cart Error')
                        }
                    });
            }else if (vm.checkinDate) { // Stay
                this.$http
                    .post("/cart", { 
                        authenticity_token: vm.auth_token,
                        cart_item: {
                            room_id: vm.product.id,
                            quantity: parseInt(vm.count),
                            remark: req,
                            checkin_date: vm.checkinDate,
                            checkout_date: vm.checkoutDate
                        },
                    })
                    .then(response => {
                        //console.log(response.data);
                        //console.log('Added to Cart');
                        window.location.href = '/stores/' + vm.store.permalink;
                    })
                    .catch(error => {
                        if (error.response) {
                            //console.log(error.response)
                        }else{
                            //console.log('Add Cart Error')
                        }
                    });
            } else { // Eat & Buy
                this.$http
                    .post("/cart", { 
                        authenticity_token: vm.auth_token,
                        cart_item: {
                            product_id: vm.product.id,
                            quantity: parseInt(vm.count),
                            remark: req,
                            product_variant_id: vm.variation,
                            addons_attributes: addition,
                        },
                    })
                    .then(response => {
                        //console.log(response.data);
                        //console.log('Added to Cart');
                        window.location.href = '/stores/' + vm.store.permalink;
                    })
                    .catch(error => {
                        if (error.response) {
                            //console.log(error.response)
                        }else{
                            //console.log('Add Cart Error')
                        }
                    });
            }
        }
    },
    computed: {
        dateslots: function() {
            var slot = [];
            if (this.appointment_slots)
            for (var lk = 0; lk < this.appointment_slots.length; lk++) {
                if (this.appointment_slots[lk].available_slot) {
                    slot = slot.concat(this.appointment_slots[lk].date);
                }
            }
            var unique = [...new Set(slot)]; 

            return unique;
        },
        timeslots: function() {
            var slot = [];
            for (var lj = 0; lj < this.appointment_slots.length; lj++) { 
                if (this.appointment_slots[lj].available_slot && this.appointment_slots[lj].date == this.fixDate) { 
                    slot = slot.concat(this.appointment_slots[lj]);
                } 
            }
            return slot;
        }
    }
}
</script>