<template>
    <div class="market-div">
        <head-nav @changetab="changeTab" :current="current_tab"></head-nav>
        <div v-if="current_user" id="point-nav" class="point point-down"><span class="point-num">{{ current_user.loyalty_points }}</span> points</div>

        <div v-if="!isloaded" id="cover-spin"></div>
        <div v-else id="market" class="market-main">
            <div v-if="!current_user" class="sign-in-banner">
                <div class="row m-0 sign-in-switch">
                    <div class="font-weight-bold col-9 pl-0 pr-2">To enjoy a better experience of our apps</div>
                    <div v-if="noLoc" class="col-3 p-0 d-flex align-items-start">
                        <span class="mr-2 label-sm ml-auto">Off</span>
                        <md-switch v-model="usingLoc"></md-switch>
                        <span class="ml-2 label-sm">On</span>
                    </div>
                </div>
                <div>Please <span v-if="noLoc">turn on your location services and </span>login/ register an accounts to access to your rewards points</div>
                <button class="md-primary md-raised sign-in-btn mr-3" @click="showDialog = true;regLoginTab = 'register-tab'">Register</button>
                <button class="md-primary md-raised sign-in-btn" @click="showDialog = true;regLoginTab = 'login-tab'">Login</button>
            </div>

            <div v-if="current_tab == 'Stay'" class="slide-choice-div border-0" style="margin:0 -20px 30px;box-shadow: 0px 5px 8px rgba(186, 186, 186, 0.25);">
                <div class="row">
                    <div class="col pr-2 select-one-date room_dates">
                        <md-datepicker v-model="inDate" :md-disabled-dates="disabledcheckinDates" md-immediately>
                            <label>Check In Date</label>
                            <span v-if="inDay" class="date-day">{{days[inDay]}}</span>
                        </md-datepicker>
                    </div>
                <div class="col pl-2 select-one-date room_dates">
                        <md-datepicker v-model="outDate" :md-disabled-dates="disabledcheckoutDates" md-immediately>
                            <label>Check Out Date</label>
                            <span v-if="outDay" class="date-day">{{days[outDay]}}</span>
                        </md-datepicker>
                    </div>
                </div>
                <div class="d-flex justify-content-center"><button class="btn room-search-btn px-4" @click="goRooms()">
                    <svg width="12" height="12" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.8776 7.78108L7.12494 6.02871C7.04583 5.94961 6.93859 5.90567 6.82609 5.90567H6.53954C7.02473 5.28523 7.31304 4.50483 7.31304 3.65589C7.31304 1.63636 5.67639 0 3.65652 0C1.63664 0 0 1.63636 0 3.65589C0 5.67542 1.63664 7.31179 3.65652 7.31179C4.5056 7.31179 5.28613 7.02353 5.90668 6.53842V6.82492C5.90668 6.93741 5.95063 7.04462 6.02974 7.12372L7.78241 8.87609C7.94765 9.0413 8.21486 9.0413 8.37835 8.87609L8.87584 8.37867C9.04109 8.21346 9.04109 7.94629 8.8776 7.78108ZM3.65652 5.90567C2.41365 5.90567 1.40635 4.9003 1.40635 3.65589C1.40635 2.41324 2.4119 1.40611 3.65652 1.40611C4.89938 1.40611 5.90668 2.41148 5.90668 3.65589C5.90668 4.89854 4.90114 5.90567 3.65652 5.90567Z" fill="#BB935A"/>
                    </svg>
                    <span class="pl-2">Search</span></button>
                </div>
            </div>
            
            <div class="banner-container container-fluid px-0">
                <swiper :options="swiperOptions">
                    <swiper-slide v-for="(banner, index) in banners" :key="index">
                        <a :href="banner.url"><img :src="banner.banner_photo.url" class="banner-img"></a>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>

            <div v-if="zecategory.subcategories && zecategory.subcategories.length" class="sect-title">Most Popular</div>
            <category-cards :category="zecategory"></category-cards>

            <div class="sect-title">Top Picks</div>
            <div v-if="dailysuggest.length" class="d-flex flex-wrap" style="margin:-8px -2% 0;">
                <div v-for="(suggest, index) in dailysuggest" :key="index" class="prodcard">
                    <div v-if="suggest.itemable.inventory_count == 0 || suggest.itemable.available_unit == 0" class="position-relative">
                        <div class="position-absolute h-100 w-100 text-center" style="background:rgba(255,255,255,0.5);z-index:2;"></div>
                        <img v-if="suggest.itemable.featured_photo.url" class="prodcard-img" :src="suggest.itemable.featured_photo.url" alt="prod-img">
                        <img v-else src='/images/placeholder.png' alt='prod-img' class="prodcard-img">
                    </div>
                    <a v-else :href="'/stores/'+suggest.store_permalink+ prodtype +suggest.itemable.permalink + roomDate">
                        <img v-if="suggest.itemable.featured_photo.url" class="prodcard-img" :src="suggest.itemable.featured_photo.url" alt="prod-img">
                        <img v-else src='/images/placeholder.png' alt='prod-img' class="prodcard-img">
                    </a>
                    <div v-if="suggest.itemable.inventory_count" class="prodcard-title"><a :href="'/stores/'+suggest.store_permalink+ prodtype +suggest.itemable.permalink + roomDate">{{ suggest.itemable.name }}</a></div>
                    <div v-else class="prodcard-title">{{ suggest.itemable.name }}</div>
                    <div class="d-flex flex-wrap">
                        <div v-if="suggest.itemable.subcategories && suggest.itemable.subcategories.length" class="prodcard-cate">{{ suggest.itemable.subcategories[0] }}</div>
                        <div v-if="suggest.itemable.inventory_count == 0 || suggest.itemable.available_unit == 0" class="prodcard-ava"><div class="xdot"></div>Not available</div>
                        <div v-else class="prodcard-ava"><div class="dot"></div>Available</div>
                    </div>
                    <div v-if="suggest.itemable.sales_price_cents != suggest.itemable.original_price_cents && suggest.itemable.sales_price_cents" class="prodcard-price">
                        <span class="slash-price">{{ suggest.itemable.original_price_currency }} {{ (suggest.itemable.original_price_cents/100).toFixed(2) }}</span> 
                        <span style="color:red;">{{ suggest.itemable.sales_price_currency }} {{ (suggest.itemable.sales_price_cents/100).toFixed(2) }}</span>
                    </div>
                    <div v-else class="prodcard-price">
                        {{ suggest.itemable.original_price_currency }} {{ (suggest.itemable.original_price_cents/100).toFixed(2) }}
                    </div>
                </div>
            </div>
            <div v-else class="text-center py-5">No suggested product found.</div>
        </div>

        <a href="/qrscan" class="qr-float-box">
            <div class="m-auto d-flex justify-content-center">
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.57 6H1.9C2.0045 6 2.09 5.91563 2.09 5.8125V1.6875H6.27C6.3745 1.6875 6.46 1.60312 6.46 1.5V0.1875C6.46 0.084375 6.3745 0 6.27 0H1.995C1.102 0 0.38 0.7125 0.38 1.59375V5.8125C0.38 5.91563 0.4655 6 0.57 6ZM12.73 1.6875H16.91V5.8125C16.91 5.91563 16.9955 6 17.1 6H18.43C18.5345 6 18.62 5.91563 18.62 5.8125V1.59375C18.62 0.7125 17.898 0 17.005 0H12.73C12.6255 0 12.54 0.084375 12.54 0.1875V1.5C12.54 1.60312 12.6255 1.6875 12.73 1.6875ZM6.27 16.3125H2.09V12.1875C2.09 12.0844 2.0045 12 1.9 12H0.57C0.4655 12 0.38 12.0844 0.38 12.1875V16.4062C0.38 17.2875 1.102 18 1.995 18H6.27C6.3745 18 6.46 17.9156 6.46 17.8125V16.5C6.46 16.3969 6.3745 16.3125 6.27 16.3125ZM18.43 12H17.1C16.9955 12 16.91 12.0844 16.91 12.1875V16.3125H12.73C12.6255 16.3125 12.54 16.3969 12.54 16.5V17.8125C12.54 17.9156 12.6255 18 12.73 18H17.005C17.898 18 18.62 17.2875 18.62 16.4062V12.1875C18.62 12.0844 18.5345 12 18.43 12ZM18.81 8.15625H0.19C0.0855 8.15625 0 8.24063 0 8.34375V9.65625C0 9.75937 0.0855 9.84375 0.19 9.84375H18.81C18.9145 9.84375 19 9.75937 19 9.65625V8.34375C19 8.24063 18.9145 8.15625 18.81 8.15625Z" fill="#BB935A"/>
                </svg>
            </div>
            <div class="scan-qr">Scan QR</div>
        </a>

        <md-dialog :md-active.sync="showDialog">
            <button class="sign-in-xbtn" @click="showDialog = false">✕</button>
            <div id="login-dialog">
                <md-tabs :md-active-tab="regLoginTab" md-alignment="fixed">
                    <md-tab id="register-tab" md-label="Register">
                        <reg-form :authToken="auth_token"></reg-form>
                    </md-tab> 

                    <md-tab id="login-tab" md-label="Login">
                        <login-form :authToken="auth_token"></login-form>
                    </md-tab>
                </md-tabs>
            </div> 
        </md-dialog>
    </div>
</template>

<script>
import CategoryCards from './categoryCards.vue';
import HeadNav from './headNav.vue';
import RegForm from './regForm.vue';
import LoginForm from './loginForm.vue';

    export default {
        name: 'market-place',
        props:['tabs','auth_token','current_user'],
        components: {
            CategoryCards,
            HeadNav,
            RegForm,
            LoginForm,
        },
        data() {

            var curr = 'Eat';
            const urlParams = new URLSearchParams(window.location.search);
            if (!urlParams.get('category')) {
                if (history.pushState) {
                    var newurl = window.location.href + '?category=eat';
                    window.history.pushState({
                        path: newurl
                    }, '', newurl);
                }
            } else {
                curr = urlParams.get('category').charAt(0).toUpperCase() + urlParams.get('category').slice(1)
            }

            return {
                isloaded: false,
                categories: [],
                zecategory: null,
                banners: [],
                dailysuggest: [],
                swiperOptions: {
                    direction: 'horizontal',
                    slidesPerView: 1,
                    spaceBetween: 20,
                    mousewheel: false,
                    loop: false,
                    initialSlide: 0,
                    autoplay: {
                        delay: 3000,
                        stopOnLastSlide: false,
                        disableOnInteraction: false,
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    //breakpoints: {},
                },
                array: [],
                boolean: false,
                showDialog: false,
                regLoginTab: 'register-tab',
                usingLoc: false,
                noLoc: true,
                position: {
                    lat: null,
                    lng: null
                },
                current_tab: curr,
                days: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
                inDate: null,
                inDay: null,
                inFormat: null,
                outDate: null,
                outDay: null,
                outFormat: null,
                disabledcheckinDates: date => {
                    return date.getTime() < (Date.now() - 86400000);
                },
                disabledcheckoutDates: date => {
                    return date.getTime() < Date.now();
                },
            }
        },
        computed: {
            roomDate() {
                let vm = this;
                var param = '';
                var today = new Date();
                var tomorrow = new Date(today);
                tomorrow.setDate(tomorrow.getDate() + 1);
                var third = new Date(tomorrow);
                third.setDate(third.getDate() + 1);

                if (vm.current_tab == 'Stay') {
                    param = '?checkin_date='+ vm.formatDate(today) +'&checkout_date='+ vm.formatDate(tomorrow);
                }
                return param;
            },
            prodtype() {
                var type = '/products/';
                if (this.current_tab == 'Stay') {
                    type = '/rooms/';
                } else {
                    type = '/products/';
                }
                return type;
            }
        },
        watch: {
            usingLoc(val) {
                if (val) { this.getLocation() }
            },
            inDate(val) {
                this.inDay = val.getDay();
                this.inFormat = this.formatDate(val);
                this.disabledcheckoutDates = date => {
                    return date.getTime() < (Number(val) + 86400000);
                };
            },
            outDate(val) {
                this.outDay = val.getDay();
                this.outFormat = this.formatDate(val);
                this.disabledcheckinDates = date => {
                    return date.getTime() > (Number(val) - 86400000) || date.getTime() < (Date.now() - 86400000);
                };
            },
            current_tab(val) {
                this.isloaded = false;
                if (history.pushState) {
                    var newurl = window.location.href.split('?')[0] + '?category=' + val.toLowerCase();
                    window.history.pushState({
                        path: newurl
                    }, '', newurl);
                }
                var getz = [];
                getz = this.categories.filter(function(u) {
                    return u.name == val
                })
                this.zecategory = getz[0]
                this.getBanners(val);
                this.getSuggest(val);
            }
        },
        mounted() {
            this.getCat();
            this.getBanners(this.current_tab.toLowerCase());
            this.getSuggest(this.current_tab.toLowerCase());
            this.getLocation();
        },
        methods: {
            getLocationSuccess(position) {
                this.locationError = "";
                this.position.lat = position.coords.latitude;
                this.position.lng = position.coords.longitude;
                //console.log(this.position)
                this.noLoc = false;
            },
            getLocationFail() {
                this.locationError = "Please allow browser to track your location.";
            },
            getLocation() {
                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(
                    this.getLocationSuccess,
                    this.getLocationFail
                    );
                } else {
                    this.locationError = "Your browser not supported this feature.";
                }
            },
            getCat: function() {
                let vm = this;
                this.$http
                    .get("/categories.json")
                    .then(response => {
                        var res = response.data;
                        vm.categories = res;
                        var getz = [];
                        getz = this.categories.filter(function(u) {
                            return u.name == vm.current_tab
                        })
                        vm.zecategory = getz[0]
                    })
                    .catch(error => {
                        console.log('Get Error')
                    });
            },
            getBanners: function(cat) {
                let vm = this;
                this.$http
                    .get("/featured_banners.json?category="+cat)
                    .then(response => {
                        var res = response.data.data;
                        var ares = res.sort((a,b) => (a.display_order > b.display_order) ? 1 : ((b.display_order > a.display_order) ? -1 : 0));
                        vm.banners = ares;
                    })
                    .catch(error => {
                        console.log('Get Error')
                    });
            },
            getSuggest: function(cat) {
                let vm = this;
                this.$http
                    .get("/daily_suggestions.json?category="+cat)
                    .then(response => {
                        var res = response.data.data;
                        vm.dailysuggest = res;
                        vm.isloaded = true;
                    })
                    .catch(error => {
                        console.log('Get Error')
                    });
            },
            changeTab: function(cur) {
                this.current_tab = cur;
            },
            formatDate: function(d) {
                var month = '' + (d.getMonth() + 1);
                var day = '' + d.getDate();
                var year = d.getFullYear();

                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;

                return [year, month, day].join('-');
            },
            goRooms: function() {
                if (!this.inDate || !this.outDate) {
                    alert('Please choose both check in and check out dates.')
                } else {
                    window.location.href = '/stores/regatta-suites?checkin_date='+this.inFormat+'&checkout_date='+this.outFormat;
                }
            },
        }
    }
</script>

<style scoped>
.scan-qr {
    color: #BB935A;
    font-weight: 600;
    font-size: 12px;
}

.qr-float-box {
    position: fixed;
    z-index: 10;
    padding: 10px 5px 10px 15px;
    right: 0;
    bottom: 200px;
    border-radius: 30px 0px 0px 30px;
    background: #FFFFFF;
    box-shadow: 0px 3px 10px rgba(255, 190, 102, 0.32);
}
</style>