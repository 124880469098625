// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
  
// import style
import '../stylesheets/application'
import './serviceworker-companion.js'
import 'swiper/css/swiper.css'

import $ from 'jquery'
import axios from 'axios'
import Vue from 'vue/dist/vue.esm'
import VueMaterial from 'vue-material'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBilVCinzoR14Jcd23-GUGTHoqLosoHhqs',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
  },
  installComponents: true
})
Vue.use(VueMaterial)
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

Vue.prototype.$http = axios

import ActionCable from 'actioncable' 
Vue.prototype.$cable = ActionCable.createConsumer('/cable')



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

const files = require.context('./components/web', true, /\.vue$/i)
files.keys().map(key => {
  const component = key.split('/').pop().split('.')[0];
  Vue.component(component, () => import(`./components/web/${component}`));
})

// import MarketPlace from '../components/web/marketPlace.vue'
// import FootNav from '../components/web/footNav.vue'
// import ExplorePage from '../components/web/explorePage.vue'
// import NotiPage from '../components/web/NotiPage.vue'
// import ProductSlide from '../components/web/productSlide.vue'
// import CartPage from '../components/web/cartPage.vue'
// import UserDashboard from '../components/web/userDashboard.vue'
// import VendorPage from '../components/web/vendorPage.vue'
// import ViewAddresses from '../components/web/viewAddresses.vue'
// import OrderDetails from '../components/web/orderDetails.vue'
// import OrderHistory from '../components/web/orderHistory.vue'
// import CategoryPage from '../components/web/categoryPage.vue'
// import Notification from '../components/web/notification.vue'

// Vue.component('notification', Notification)
// Vue.component('market-place', MarketPlace)
// Vue.component('foot-nav', FootNav)
// Vue.component('explore-page', ExplorePage)
// Vue.component('noti-page', NotiPage)
// Vue.component('product-slide', ProductSlide)
// Vue.component('cart-page', CartPage)
// Vue.component('user-dashboard', UserDashboard)
// Vue.component('vendor-page', VendorPage)
// Vue.component('view-addresses', ViewAddresses)
// Vue.component('order-details', OrderDetails)
// Vue.component('order-history', OrderHistory)
// Vue.component('category-page', CategoryPage)

// Vue.component('notification', Notification)
// Vue.component('market-place', MarketPlace)
// Vue.component('foot-nav', FootNav)
// Vue.component('explore-page', ExplorePage)
// Vue.component('noti-page', NotiPage)
// Vue.component('product-slide', ProductSlide)
// Vue.component('cart-page', CartPage)
// Vue.component('user-dashboard', UserDashboard)
// Vue.component('vendor-page', VendorPage)
// Vue.component('view-addresses', ViewAddresses)
// Vue.component('order-details', OrderDetails)
// Vue.component('order-history', OrderHistory)
// Vue.component('category-page', CategoryPage)

window.addEventListener('load', function () {
    var app = new Vue({
        el: '#app'
    })

    // Hide Header on on scroll down
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = 120;

    $(window).scroll(function() {
        didScroll = true;
    });

    setInterval(function() {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    function hasScrolled() {
        var st = $(document).scrollTop();
        // $(window).height() = 812px for iphone X <- device height
        // $(document).height() = 1644px <- body height
        // Make sure they scroll more than delta
        if(Math.abs(lastScrollTop - st) <= delta)
            return;
        
        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > navbarHeight/2){
            // Scroll Down
            $('#top-nav').removeClass('nav-down').addClass('nav-up');
            $('#point-nav').removeClass('point-down').addClass('point-up');
            $('#filter-row').removeClass('filter-down').addClass('filter-up');
            $('#foot-nav').removeClass('foot-up').addClass('foot-down');
        } else {
            // Scroll Up
            if(st + $(window).height() < $(document).height()) {
              $('#top-nav').removeClass('nav-up').addClass('nav-down');
              $('#point-nav').removeClass('point-up').addClass('point-down');
              $('#filter-row').removeClass('filter-up').addClass('filter-down');
              $('#foot-nav').removeClass('foot-down').addClass('foot-up');
            }
        }
        
        lastScrollTop = st;
    }

});