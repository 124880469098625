<template>
    <!-- <form @submit.prevent="submitForm" class="form-padding"> -->
    <form action="/sign_in" method="post" class="form-padding">
        <input class="input-field" name="customer[email]" v-model="email" type="email" placeholder="Email" required>
        <input class="input-field" name="customer[password]" v-model="password" type="password" placeholder="Password" required>
        <input type="hidden" name="customer[remember_me]" value="1">
        <input type="hidden" name="authenticity_token" :value="token">
        <input type="hidden" name="redirect_path" :value="current_path">
        <div style="color:#FF5757;font-size:12px" v-if="errorlog">{{errorlog}}</div>
        <div style="color:#BB935A;font-size:12px"><a href="/password/new">Forgot password? Reset here</a></div>
        <button type="submit" class="submit-btn">Login</button>
        <!-- <div class="form-text">Don't have an account? <span style="font-weight:bold;color: #BB935A;" @click="regLoginTab='register-tab'">Register here</span></div> -->
    </form>
</template>

<script>
    export default {
        name: 'register-form',
        props: ['authToken'],
        data() {
            return {
                email: '',
                password: '',
                errorlog: null,
                token: '',
                current_path: ''
            }
        },
        methods: {
            submitForm: function() {
                
                let vm = this;
				this.$http
					.post("/sign_in.json", { 
                        authenticity_token: vm.authToken,
                        sign_in: {
                            email: vm.email,
                            password: vm.password,
                            remember_me: 1,
                        },
                    })
					.then(response => {
						//console.log(response.data);
                        vm.errorlog = null;
                        window.location.reload();
					})
					.catch(error => {
                        if (error.response) {
                            //console.log(error.response)
                            vm.errorlog = 'Error: ' + error.response.data;
                        }else{
                            vm.errorlog = 'Login Failed';
                        }
					});
            }
        },
        mounted() {
            this.token = document.getElementsByName('csrf-token')[0].getAttribute('content')
            this.current_path = window.location.pathname
            // this.$http.defaults.headers.common['X-CSRF-Token'] = token
        },
    }
</script>