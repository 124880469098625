<template>
    <div>
      <div id='desc'></div>
      <div id='target' style="display:none;"><slot></slot></div>
    </div>
</template>

<script>
export default {
  name: "desc-show",
  props:['desc'],
  methods: { 
    decodeHtml: function(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }
  },
  mounted() {
    document.getElementById('desc').innerHTML = this.decodeHtml(document.getElementById('target').innerHTML)
  }
};
</script>