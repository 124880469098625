<template>
<div>
  <div v-if="!isloaded" id="cover-spin"></div>

  <div v-if="latest_cart.cart_items.length" class="cart-div">
    <div class="page-header">{{ cart_store.name }}</div>

    <ul v-if="errors" style="color:red;margin:10px 0;">
      <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
    </ul>

    <div v-if="!latest_cart.cart_items[0].appointment && !latest_cart.cart_items[0].room_bookings.length && latest_cart.cart_items[0].itemable_type == 'Product'" class="checkout-switch">
      <span id="idel" class="mr-3">Delivery</span>
      <md-switch v-model="takeaway"></md-switch>
      <span id="islc">Self Collect</span>
    </div>
    <!-- Deliver address -->
    <div v-if="(!takeaway && !latest_cart.cart_items[0].room_bookings.length && cart_store.category.name != 'Play')" class="cart-section">
      <div class="d-flex">
        <div v-if="latest_cart.cart_items[0].appointment && cart_store.category.name == 'Fix'" class="slide-sect-title">Service Required at</div>
        <div v-else class="slide-sect-title">Deliver to</div>
        <div @click="openLoc()" class="change-details-btn ml-auto">Change Address</div>
      </div>
      <div class="loc-req">
        <div v-if="selected_address && selected_address.distance > delivery_distance" style="color:red;" class="pb-3">⚠️ This address is out of delivery range.</div>
        <div class="row m-0 pb-4">
          <div class="col-2 p-0">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.7 3.5H6.3V6.3H3.5V7.7H6.3V10.5H7.7V7.7H10.5V6.3H7.7V3.5ZM7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM7 12.6C3.913 12.6 1.4 10.087 1.4 7C1.4 3.913 3.913 1.4 7 1.4C10.087 1.4 12.6 3.913 12.6 7C12.6 10.087 10.087 12.6 7 12.6Z" fill="#ECBC7B" />
            </svg>
          </div>
          <div class="col-10 p-0">
            <input class="address-unit-input" v-model="address_unit" placeholder="Add Unit/Lot Number (Optional)">
          </div>
        </div>
        <div class="row m-0">
          <div class="col-2 p-0">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.20491 2.26267L8.18059 0.858338L6.81716 0.881948L6.84148 2.28628C5.46172 2.46464 4.18361 3.10687 3.21703 4.10751C2.25046 5.10815 1.65286 6.40773 1.52239 7.79284L0.118052 7.81716L0.141662 9.18059L1.546 9.15627C1.72435 10.536 2.36658 11.8141 3.36723 12.7807C4.36787 13.7473 5.66744 14.3449 7.05256 14.4754L7.07687 15.8797L8.44031 15.8561L8.41599 14.4518C9.79575 14.2734 11.0739 13.6312 12.0404 12.6305C13.007 11.6299 13.6046 10.3303 13.7351 8.9452L15.1394 8.92088L15.1158 7.55745L13.7115 7.58176C13.5331 6.202 12.8909 4.92389 11.8902 3.95732C10.8896 2.99074 9.59002 2.39315 8.20491 2.26267ZM7.71137 13.141C5.07313 13.1867 2.90241 11.0899 2.85672 8.45165C2.81103 5.81341 4.90786 3.64269 7.5461 3.59701C10.1843 3.55132 12.3551 5.64814 12.4007 8.28638C12.4464 10.9246 10.3496 13.0953 7.71137 13.141Z" fill="#ECBC7B" />
              <path d="M7.58151 5.64215C6.07492 5.66824 4.87578 6.90965 4.90187 8.41624C4.92796 9.92283 6.16936 11.122 7.67595 11.0959C9.18255 11.0698 10.3817 9.82839 10.3556 8.3218C10.3295 6.81521 9.0881 5.61607 7.58151 5.64215ZM7.65234 9.73245C6.90246 9.74544 6.27829 9.14252 6.2653 8.39263C6.25232 7.64274 6.85523 7.01857 7.60512 7.00559C8.35501 6.9926 8.97918 7.59552 8.99216 8.34541C9.00515 9.0953 8.40223 9.71946 7.65234 9.73245Z" fill="#ECBC7B" />
            </svg>
          </div>
          <div class="col-10 p-0">
            <span v-if="selected_address" style="color:#373737;">{{ selected_address.street1 }}</span>
            <span v-else @click="openLoc()">Add Address</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="latest_cart.cart_items[0].room_bookings.length" class="slide-choice-div border-0">
      <div class="slide-sect-title m-0 pb-4">Your Stay Period</div>
      <div class="w-100 hotel-datepicker room_dates no_changing">
        <md-datepicker v-model="checkinDate" :md-disabled-dates="disabledcheckinDates">
          <label>Check in Date</label>
          <span v-if="checkinDay != null" class="date-day">{{days[checkinDay]}}</span>
        </md-datepicker>
      </div>
      <div class="w-100 hotel-datepicker room_dates no_changing">
        <md-datepicker v-model="checkoutDate" :md-disabled-dates="disabledcheckoutDates">
          <label>Check out Date</label>
          <span v-if="checkoutDay != null" class="date-day">{{days[checkoutDay]}}</span>
        </md-datepicker>
      </div>
      <div class="slide-sect-title m-0 py-4">Guest Information</div>
      <div v-if="guest_info.length">
        <div class="d-flex mb-2"><div class="col-6">Title:</div><div><b>{{ guest_info[0] }}</b></div></div>
        <div class="d-flex mb-2"><div class="col-6">First name:</div><div><b>{{ guest_info[1] }}</b></div></div>
        <div class="d-flex mb-2"><div class="col-6">Last name:</div><div><b>{{ guest_info[2] }}</b></div></div>
        <div class="d-flex mb-2"><div class="col-6">Email:</div><div><b>{{ guest_info[3] }}</b></div></div>
        <div class="d-flex mb-2"><div class="col-6">Phone number:</div><div><b>{{ guest_info[4] }}</b></div></div>
        <div v-if="guest_remark" class="d-flex mb-2"><div class="col-6">Remark:</div><div><b>{{ guest_remark }}</b></div></div>
      </div>
    </div>

    <!-- Deliver time -->
    <div v-if="!takeaway && !latest_cart.cart_items[0].appointment && !latest_cart.cart_items[0].room_bookings.length" class="border-bottom py-1"> </div>
    <div v-if="!latest_cart.cart_items[0].appointment && !latest_cart.cart_items[0].room_bookings.length" class="cart-section">
      <div class="d-flex">
        <div class="slide-sect-title">Schedule <span v-if="!takeaway">Delivery</span><span v-else>Pick Up</span> Time</div>
        <div v-if="ava_times.length" class="change-details-btn ml-auto" @click="changetime = true">Change Date &amp; Time</div>
      </div>
      <div v-if="ava_times.length" class="d-flex align-items-center mt-3">
        <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.9 1.4H10.5V0.7C10.5 0.514348 10.4263 0.336301 10.295 0.205025C10.1637 0.0737498 9.98565 0 9.8 0C9.61435 0 9.4363 0.0737498 9.30503 0.205025C9.17375 0.336301 9.1 0.514348 9.1 0.7V1.4H4.9V0.7C4.9 0.514348 4.82625 0.336301 4.69497 0.205025C4.5637 0.0737498 4.38565 0 4.2 0C4.01435 0 3.8363 0.0737498 3.70503 0.205025C3.57375 0.336301 3.5 0.514348 3.5 0.7V1.4H2.1C1.54305 1.4 1.0089 1.62125 0.615076 2.01508C0.221249 2.4089 0 2.94305 0 3.5V11.9C0 12.457 0.221249 12.9911 0.615076 13.3849C1.0089 13.7788 1.54305 14 2.1 14H11.9C12.457 14 12.9911 13.7788 13.3849 13.3849C13.7788 12.9911 14 12.457 14 11.9V3.5C14 2.94305 13.7788 2.4089 13.3849 2.01508C12.9911 1.62125 12.457 1.4 11.9 1.4ZM12.6 11.9C12.6 12.0857 12.5263 12.2637 12.395 12.395C12.2637 12.5263 12.0857 12.6 11.9 12.6H2.1C1.91435 12.6 1.7363 12.5263 1.60503 12.395C1.47375 12.2637 1.4 12.0857 1.4 11.9V7H12.6V11.9ZM12.6 5.6H1.4V3.5C1.4 3.31435 1.47375 3.1363 1.60503 3.00503C1.7363 2.87375 1.91435 2.8 2.1 2.8H3.5V3.5C3.5 3.68565 3.57375 3.8637 3.70503 3.99497C3.8363 4.12625 4.01435 4.2 4.2 4.2C4.38565 4.2 4.5637 4.12625 4.69497 3.99497C4.82625 3.8637 4.9 3.68565 4.9 3.5V2.8H9.1V3.5C9.1 3.68565 9.17375 3.8637 9.30503 3.99497C9.4363 4.12625 9.61435 4.2 9.8 4.2C9.98565 4.2 10.1637 4.12625 10.295 3.99497C10.4263 3.8637 10.5 3.68565 10.5 3.5V2.8H11.9C12.0857 2.8 12.2637 2.87375 12.395 3.00503C12.5263 3.1363 12.6 3.31435 12.6 3.5V5.6Z"
            fill="#F1CD9C" />
        </svg>
        <span v-if="d_day == '0'" class="ml-2 mr-4">Today</span>
        <span v-if="d_day == '1'" class="ml-2 mr-4">Tomorrow</span>
        <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM7 12.8125C3.79063 12.8125 1.1875 10.2094 1.1875 7C1.1875 3.79063 3.79063 1.1875 7 1.1875C10.2094 1.1875 12.8125 3.79063 12.8125 7C12.8125 10.2094 10.2094 12.8125 7 12.8125Z"
            fill="#F1CD9C" />
          <path
            d="M9.72969 8.97812L7.50156 7.36719V3.5C7.50156 3.43125 7.44531 3.375 7.37656 3.375H6.625C6.55625 3.375 6.5 3.43125 6.5 3.5V7.80313C6.5 7.84375 6.51875 7.88125 6.55156 7.90469L9.13594 9.78906C9.19219 9.82969 9.27031 9.81719 9.31094 9.7625L9.75781 9.15312C9.79844 9.09531 9.78594 9.01719 9.72969 8.97812Z"
            fill="#F1CD9C" />
        </svg>
        <span class="mx-2">{{d_time}}</span>
      </div>
      <div v-else style="color:red;">⚠️ The store is not available today/tomorrow. Please try again next time.</div>
    </div>
    <md-dialog :md-active.sync="changetime">
      <button class="sign-in-xbtn" @click="changetime = false">✕</button>
      <md-dialog-title style="text-align:center;color:#BB935A;font-size:18px;">Select your delivery time
      </md-dialog-title>
      <div style="padding:0 32px 15px;">
        <md-field>
          <label for="d_day">Day</label>
          <md-select v-model="d_day" name="d_day" id="d_day">
            <md-option v-for="(ava, index) in ava_days" :key="index" :value="ava.value">{{ ava.name }}</md-option>
          </md-select>
        </md-field>
        <md-field>
          <label for="d_time">Time</label>
          <md-select v-model="d_time" name="d_time" id="d_time">
            <md-option v-for="(ava, index) in ava_times" :key="index" :value="ava">{{ ava }}</md-option>
          </md-select>
        </md-field>
        <button @click="changetime = false" class="submit-btn">Proceed</button>
      </div>
    </md-dialog>
    <!-- Order Summary -->
    <div class="cart-section border-top">
      <div class="d-flex">
        <div class="slide-sect-title">Order Summary</div>
        <a :href="'/stores/'+ latest_cart.store_id" class="change-details-btn ml-auto">Add Items</a>
      </div>
      <div v-for="(order, index) in latest_cart.cart_items" :key="index" class="d-flex justify-content-between mt-3" style="color: #373737;">
        <div class="d-flex" style="margin-left:-5px;">
          <button v-if="order.quantity == 1" @click="destroy_target = order.id;confirmRemove = true;" class="order-count-btn">-</button>
          <button v-else @click="editQuantity(order.quantity, -1,  order.id, index)" class="order-count-btn">-</button>
          <div class="order-count">{{order.quantity}}</div>
          <button @click="editQuantity(order.quantity, 1,  order.id, index)" class="order-count-btn">+</button>
        </div>
        <div class="w-50 text-capitalize">
          <div :class="{'slash-price':!order.itemable_id || !order.item_details.itemable.active || (order.item_details.itemable.inventory_count && order.item_details.itemable.inventory_count == 0) || (order.item_details.itemable.available_slot && order.item_details.itemable.available_slot == 0) || (order.item_details.itemable.available_unit && order.item_details.itemable.available_unit == 0)}">{{order.item_details.itemable.name}}</div>
          <div style="font-size:0.85rem" v-if="order.product_variant" :class="{'slash-price':!order.product_variant.is_available}">• {{order.product_variant.name}}</div>
          <div style="font-size:0.85rem" v-if="order.addons.length">+{{order.addons.length}} Add-ons</div>
          <div style="font-size:0.85rem" v-if="order.appointment">• {{order.appointment.appointment_slot.date}}</div>
          <div style="font-size:0.85rem" v-if="order.appointment">• {{parseTime(order.appointment.appointment_slot.start_time)}} - {{parseTime(order.appointment.appointment_slot.end_time)}}</div>
          <div style="font-size:0.85rem;font-style:italic;" v-if="order.remark && !guest_info.length">{{ order.remark }}</div>
          <div style="font-size:0.85rem;color:red;" v-if="!order.item_details.itemable.active || (order.item_details.itemable.inventory_count && order.item_details.itemable.inventory_count == 0) || (order.item_details.itemable.available_slot && order.item_details.itemable.available_slot == 0) || (order.item_details.itemable.available_unit && order.item_details.itemable.available_unit == 0)">⚠️ This item is unavailable.</div>
          <div style="font-size:0.85rem;color:red;" v-else-if="order.product_variant && !order.product_variant.is_available">⚠️ This item is unavailable.</div>
          <div style="font-size:0.85rem;color:red;" v-else-if="!order.itemable_id">⚠️ This item is removed from the store.</div>
          <div style="font-size:0.85rem;color:red;" v-else-if="(order.itemable && order.item_details.itemable && order.itemable.updated_at != order.item_details.itemable.updated_at) || (order.product_variant_id && order.item_details.variant && order.product_variant.updated_at != order.item_details.variant.updated_at) || (order.item_details.variant && !order.product_variant_id) || (order.appointment && order.appointment.appointment_slot.updated_at != order.appointment.appointment_slot_details.appointment_slot.updated_at)">⚠️ This item's details has been changed, please remove this item and add again from the store.</div>
        </div>
        <div>{{ order.sales_price_currency }} {{(order.price_cents/100).toFixed(2)}}</div>
      </div>
      <div class="subtotal-div">
        <div class="d-flex flex-nowrap"><span class="order-item-name">Subtotal</span>
          <span class="ml-auto">{{(latest_cart.subtotal_price_cents/100).toFixed(2)}}</span>
        </div>
        <div class="d-flex flex-nowrap"><span class="order-item-name">Tax/Others ({{ taxamount.rate }}%)</span>
          <span class="ml-auto">{{ taxamount.amount }}</span>
        </div>
        <div v-if="!latest_cart.cart_items[0].room_bookings.length && !latest_cart.cart_items[0].appointment && !takeaway" class="d-flex flex-nowrap">
          <span class="order-item-name">Delivery Fee</span>
          <span class="ml-auto">{{ deliveryamount }}</span>
        </div> 
        <div class="d-flex align-items-center flex-nowrap redeem-point">
          <md-switch v-model="redeem"></md-switch>
          <span v-if="!redeem" style="color:#BB935A;">Redeem with reward points?</span>
          <span v-if="redeem" class="order-item-name" style="color:#BB935A;">Redemeed {{redeemed_points}} points</span>
          <span v-if="redeem" class="ml-auto">- {{ (redeemed_points/100).toFixed(2) }}</span>
        </div>
      </div>
      <ul>
        <li v-if="!latest_cart.cart_items[0].room_bookings.length && !latest_cart.cart_items[0].appointment" style="color:#BB935A;font-size:12px;margin:5px 0;">FREE delivery charges for minimum purchases of RM{{free_delivery_amount}}. Only applies for area within {{free_delivery_distance}}km from Kozi Square.</li>
        <li style="color:#BB935A;font-size:12px;margin:5px 0;">The maximum points can be redeemed is {{redeem_limit*100}}% of the Subtotal amount.</li>
        <li style="color:#BB935A;font-size:12px">Please confirm your payment within 10 minutes after placing order or else the order will be expired.</li>
      </ul>  
    </div>

    <!-- Floating total -->
    <div class="floating-total">
      <span v-show="redeem" style="font-size:12px;line-height:1;color: #ECBC7B;display:none;">Redeemed with points</span>
      <div class="row">
        <div class="col-5 d-flex align-items-center">
          <span>Total (incl. tax/others)</span>
        </div>
        <div class="col-7 flex-row-reverse d-flex align-items-end" style="font-size:18px;font-weight:bold;">
          {{ latest_cart.total_price_currency }} {{ grandtotal }}
        </div>
      </div>
      <button class="submit-btn" @click="placeOrder()">Place Order</button>
    </div>

    <md-dialog :md-active.sync="confirmRemove">
      <button class="sign-in-xbtn" @click="confirmRemove = false">✕</button>
      <md-dialog-title style="text-align:center;color:#BB935A;font-size:18px;">Remove this item?</md-dialog-title>
      <div style="padding:0 32px 15px;">
        <button @click="editQuantity(0, 0, destroy_target, null)" class="submit-btn">Proceed</button>
        <button @click="confirmRemove = false" class="submit-btn-reverse">Cancel</button>
      </div>
    </md-dialog>

    <md-dialog :md-active.sync="showNostock">
      <button class="sign-in-xbtn" @click="showNostock = false">✕</button>
      <md-dialog-title style="text-align:center;color:#BB935A;font-size:18px;">One of the item is unavailable.</md-dialog-title>
      <div style="padding:0 32px 15px;">
        <div class="text-center">Please remove it to proceed.</div>
        <button @click="showNostock = false" class="submit-btn">Ok</button>
      </div>
    </md-dialog>
  </div>

  <div v-else class="cart-div">
    <div class="page-header"> </div>
    <div class="text-center py-5"> Your cart is empty</div>
  </div>

  <md-dialog :md-active.sync="showLogin">
    <button class="sign-in-xbtn" @click="showLogin = false">✕</button>
    <div id="login-dialog">
      <md-tabs :md-active-tab="regLoginTab" md-alignment="fixed">
        <md-tab id="register-tab" md-label="Register">
          <reg-form :authToken="auth_token"></reg-form>
        </md-tab> 

        <md-tab id="login-tab" md-label="Login">
          <login-form :authToken="auth_token"></login-form>
        </md-tab>
      </md-tabs>
    </div> 
  </md-dialog>
  <form id="cartForm" action="/checkout" method="post" class="d-none">
    <input type="field" name="cart[loyalty_point_redeem]" :value="redeemed_points">
    <input v-if="d_type != 'STAY'" type="hidden" name="cart[delivery_time]" :value="d_datetime">
    <input type="hidden" name="cart[delivery_type]" :value="d_type">
    <input v-if="d_type != 'STAY' && selected_address" type="hidden" name="cart[delivery_address_id]" :value="selected_address_id">
    <input type="hidden" name="authenticity_token" :value="auth_token">
  </form>
</div>
</template>

<script>
import RegForm from './regForm.vue';
import LoginForm from './loginForm.vue';

  export default {
    components: {
      RegForm,
      LoginForm,
    },
    props:['cart_items', 'cart_store', 'cart', 'auth_token', 'current_user', 'user_addresses', 'taxes', 'redeem_limit', 'delivery_rate', 'delivery_distance', 'free_delivery_distance', 'free_delivery_amount','errors'],
    data() {
      var ztype = 'DELIVERY';
      var inDate = null;
      var inDay = null;
      var outDate = null;
      var outDay = null;

      if (this.cart_items.cart_items.length) {
        if (this.cart_items.cart_items[0].appointment) {
          ztype = 'APPOINTMENT';
        }

        if (this.cart_items.cart_items[0].room_bookings.length) {
          inDate = new Date(this.cart_items.cart_items[0].room_bookings[0].date);
          inDay = inDate.getDay();
          outDate = new Date(this.cart_items.cart_items[0].room_bookings[this.cart_items.cart_items[0].room_bookings.length - 1].date);
          outDate.setDate(outDate.getDate() + 1);
          outDay = outDate.getDay();
          ztype = 'STAY';

          var guest = this.cart_items.cart_items[0].remark.split('<Guest Details>')[1].split('<AND>');
          var guest_remark = this.cart_items.cart_items[0].remark.split('<Guest Details>')[0];
        }
      }

      var addr = null;
      var lot = '';
      var adid = null;
      if (this.user_addresses.length) {
        addr = this.user_addresses.filter(function(u) {
          return u.is_default
        })
        if (addr.length) {
          addr = addr[0];
          lot = addr.unit_number;
          adid = addr.id;
        }
      }

      var dtax = null; var sctax = null; var gtax = null;
      if (this.taxes.length) {
        for (var gi = 0;gi < this.taxes.length;gi++) {
          if (this.taxes[gi].publish_to == 'SELF_COLLECT') { sctax = this.taxes[gi].rates }
          if (this.taxes[gi].publish_to == 'DELIVERY') { dtax = this.taxes[gi].rates }
          if (this.taxes[gi].publish_to == 'GLOBAL') { gtax = this.taxes[gi].rates }
        }
      }

      return {
        takeaway: false,
        destroy_target: null,
        confirmRemove: false,
        redeem: false,
        noCard: false,
        noBank: false,
        changetime: false,
        d_day: null,
        d_time: null,
        d_type: ztype,
        regLoginTab: 'login-tab',
        showLogin: false,
        isloaded: true,
        address_unit: lot,
        selected_address: addr,
        selected_address_id: adid,
        days: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
        checkinDate: inDate,
        checkinDay: null,
        tempinDate: null,
        checkoutDate: outDate,
        checkoutDay: null,
        tempoutDate: null,
        disabledcheckinDates: date => {
          if (inDate) {
            return date.getTime() < (Number(inDate) + 86400000);
          } else {
            return true;
          }
        },
        disabledcheckoutDates: date => {
          if (outDate) {
            return date.getTime() > (Number(outDate) - 86400000) || date.getTime() < (Date.now() - 86400000);
          } else {
            return true;
          }
        },
        latest_cart: this.cart_items,
        stock: true,
        showNostock: false,
        redeemed_points: 0,
        delivery_tax: dtax,
        self_col_tax: sctax,
        global_tax: gtax,
        guest_info: guest || [],
        guest_remark: guest_remark || "",
      }
    },
    watch: {
      redeem(val) {

        if (val) {
          if (!this.current_user) {
            this.showLogin = true;
          } else {
            var rpts = this.current_user.loyalty_points;
            var max = Math.floor(this.latest_cart.subtotal_price_cents*this.redeem_limit/100)*100;

            if (rpts < 100) {
              alert('You have no enough points points.')
              this.redeem = false;
            } else if (rpts < max) {
              this.redeemed_points = Math.floor(rpts/100)*100;
            } else {
              this.redeemed_points = max;
            }
          }
        } else {
          this.redeemed_points = 0;
        }
      },
      takeaway(val) {
        if (val) {
          document.getElementById('islc').style.color = '#BB935A';
          document.getElementById('idel').style.color = '#373737';
          this.d_type = 'TAKEAWAY';
        } else {
          document.getElementById('islc').style.color = '#373737';
          document.getElementById('idel').style.color = '#BB935A';
          this.d_type = 'DELIVERY';
        }
      },
      checkinDate(val) {
        //console.log(val)
        this.checkinDay = val.getDay();
        this.disabledcheckoutDates = date => {
          return date.getTime() < (Number(val) + 86400000);
        };
        if (val != this.tempinDate && this.tempinDate) {
          this.updateDates();
        }
        this.tempinDate = val
      },
      checkoutDate(val) {
        //console.log(val)
        this.checkoutDay = val.getDay();
        this.disabledcheckinDates = date => {
          return date.getTime() > (Number(val) - 86400000) || date.getTime() < (Date.now() - 86400000);
        };
        if (val != this.tempoutDate && this.tempoutDate) {
          this.updateDates();
        }
        this.tempoutDate = val
      },
      latest_cart(val) {
        for (var f = 0; f < val.cart_items.length; f++) {
          if (!val.cart_items[f].itemable.active || (!val.cart_items[f].itemable.inventory_count && !val.cart_items[f].itemable.inventory_count == 0) || (!val.cart_items[f].itemable.available_slot && !val.cart_items[f].itemable.available_slot == 0) || (!val.cart_items[f].itemable.available_unit && !val.cart_items[f].itemable.available_unit == 0)) {
            this.stock = false;
          } else {
            this.stock = true;
          }

          if (val.cart_items[f].product_variant) { 
            if (!val.cart_items[f].product_variant.is_available) { 
              this.stock = false;
            }
          } else {
            this.stock = true;
          }
        }
      },
      d_day() {
        if (this.ava_times.length) { this.d_time = this.ava_times[0]; }
        else { this.d_time = null; }
      }
    },
    computed: {
      d_datetime() {
        var plus_d = new Date();
        plus_d.setDate(new Date().getDate()+this.d_day);
        if (this.d_time) {
          plus_d.setHours(this.d_time.split(':')[0]);
          plus_d.setMinutes(this.d_time.split(':')[1]);
        }
        plus_d.setSeconds('00');
        plus_d.setMilliseconds('000');
        return plus_d;
      },
      operation_status() {
        var status = false;
        var now = new Date();
        var today = now.getDay();
        if (this.cart_store.working_hours && this.cart_store.working_hours[today]) {
          if (this.cart_store.working_hours[today].is_open) {
            var open = this.parseTime(this.cart_store.working_hours[today].start_time);
            var close = this.parseTime(this.cart_store.working_hours[today].end_time);
            var start_hour = parseInt(open.split('.')[0])
            var end_hour = parseInt(close.split('.')[0])
            if (open.includes("P") && start_hour < 12) {start_hour = start_hour + 12;}
            if (close.includes("P") && end_hour < 12) {end_hour = end_hour + 12;}

            if (now.getHours() >= start_hour) {
              if (now.getMinutes() >= parseInt(open.split('.')[1].substring(0,2))) {
                status = true;
              }
            }
            if (now.getHours() >= end_hour) {
              if (now.getMinutes() >= parseInt(close.split('.')[1].substring(0,2))) {
                status = false;
              }
            }
          }
        }
        return status;
      },
      ava_days() {
        const now = new Date();
        const h = now.getHours();
        const m = now.getMinutes();
        const today = now.getDay();
        var ava = [{name: 'Today', value: 0}, {name: 'Tomorrow', value: 1}];

        var deli = new Date(now.getTime() + 30*60000);
        var deli_h = parseInt(deli.getHours());
        var deli_m = parseInt(deli.getMinutes());

        if (!this.operation_status) {
          if (this.cart_store.working_hours && this.cart_store.working_hours[today].start_time && this.cart_store.working_hours[today].end_time) {
            var close = this.parseTime(this.cart_store.working_hours[today].end_time);
            var end_hour = parseInt(close.split('.')[0])
            if (close.includes("P") && end_hour < 12) {end_hour = end_hour + 12;}
            if (deli_h >= end_hour) {
              ava = [{name: 'Tomorrow', value: 1}];
            }
          } else {
            ava = [{name: 'Tomorrow', value: 1}];
          }
        }

        return ava
      },
      ava_times: function() {
        var tdlist = [];
        var tmrlist = [];
        var now = new Date();
        var next = new Date(now);
        next.setDate(next.getDate() + 1)

        var deli = new Date(now.getTime() + 30*60000);
        var deli_h = parseInt(deli.getHours());
        var deli_m = parseInt(deli.getMinutes());
        if (deli_m < 10) { deli_m = '0' + deli_m}
        
        var today = now.getDay();
        var tomorrow = next.getDay();
        if (this.cart_store.working_hours && this.cart_store.working_hours[today].start_time && this.cart_store.working_hours[today].end_time && this.cart_store.working_hours[today].is_open) {
          var open = this.parseTime(this.cart_store.working_hours[today].start_time);
          var close = this.parseTime(this.cart_store.working_hours[today].end_time);
          var start_hour = parseInt(open.split('.')[0])
          var end_hour = parseInt(close.split('.')[0])
          if (open.includes("P") && start_hour < 12) {start_hour = start_hour + 12;}
          if (close.includes("P") && end_hour < 12) {end_hour = end_hour + 12;}

          for (var i = deli_h; i <= end_hour; i++) {
            if (deli_h > start_hour) {
              if (i == deli_h) {
                var time = i + ':' + deli_m;
                tdlist.push(time);
              } else {
                var time = i + ':00';
                tdlist.push(time);
              }
            } else {
              if (i == start_hour) {
                var time = i + ':30';
                tdlist.push(time);
              } else if (i > start_hour) {
                var time = i + ':00';
                tdlist.push(time);
              }
            }
          }
        }
        
        if (this.cart_store.working_hours && this.cart_store.working_hours[tomorrow].start_time && this.cart_store.working_hours[tomorrow].end_time && this.cart_store.working_hours[tomorrow].is_open) {
          var open = this.parseTime(this.cart_store.working_hours[tomorrow].start_time);
          var close = this.parseTime(this.cart_store.working_hours[tomorrow].end_time);
          var start_hour = parseInt(open.split('.')[0])
          var end_hour = parseInt(close.split('.')[0])
          if (open.includes("P") && start_hour < 12) {start_hour = start_hour + 12;}
          if (close.includes("P") && end_hour < 12) {end_hour = end_hour + 12;}

          for (var i = start_hour; i <= end_hour; i++) {
            if (i == start_hour) {
              var time = i + ':30';
              tmrlist.push(time);
            } else {
              var time = i + ':00';
              tmrlist.push(time);
            }
          }
        }
        var usingList = [];
        if (this.d_day == 1) { usingList = tmrlist; }
        else { usingList = tdlist; }

        return usingList
      },
      taxamount() {
        var lesub = this.latest_cart.subtotal_price_cents/100;
        var tax = 0;
        if (this.takeaway && this.self_col_tax) { tax = this.self_col_tax }
        else if (this.delivery_tax) { tax = this.delivery_tax }
        
        if (this.global_tax) { tax = tax + this.global_tax }
        
        var letax = lesub*tax;
        var taxobj = { rate: tax*100, amount: letax.toFixed(2) };

        return taxobj;
      },
      deliveryamount() {
        var ledel = this.delivery_rate.toFixed(2);
        if (this.latest_cart.cart_items[0].room_bookings.length || this.latest_cart.cart_items[0].appointment) {
          ledel = 0;
        } else if (this.latest_cart.subtotal_price_cents/100 >= this.free_delivery_amount) {
          if (this.selected_address && this.selected_address.distance <= this.free_delivery_distance) {
            ledel = 0;
          }
        } else if (this.takeaway) {
          ledel = 0;
        }

        return ledel;
      },
      grandtotal() {  
        var legrand = this.latest_cart.subtotal_price_cents/100 + parseFloat(this.taxamount.amount) + parseFloat(this.deliveryamount) - this.redeemed_points/100;
        return legrand.toFixed(2)
      }
    },
    methods: {
      openLoc: function() { window.location.href = "/addresses" },
      placeOrder: function() {
        if ( this.current_user == null ) {
          this.showLogin = true;
        } else if (!this.stock) {
          this.showNostock = true;
        } else if ((this.d_type == 'DELIVERY' || this.d_type == 'TAKEAWAY') && !this.ava_times.length) {
          alert('The store is unavailable. Try ordering from other stores.')
        } else if (this.takeaway) {
          document.getElementById("cartForm").submit();
        } else if (!this.user_addresses.length && !this.takeaway && this.cart_items.cart_items[0].itemable_type != "Room" && this.cart_store.category.name != 'Play') {
          alert('Please add an Address.')
        }else {
          document.getElementById("cartForm").submit();
        }
      },
      parseTime: function(str) {
        if (str) {
          var res = str.split("T")[1].split(":");
          var part = parseInt(res[0]);
          var ap = 'AM';
          if (part == 12) { 
            ap = 'PM'; 
          } else if (part > 12) {
            part = part - 12;
            ap = 'PM';
          }
          if (part < 10) {res[0] = '0' + part}
          var time = res[0] + '.' + res[1] + ap;

          return time;
        } else {
          return '';
        }
      },
      updateDates: function() {
        let vm = this;
        vm.isloaded = false;
        this.$http
          .put("/cart.json", { 
            authenticity_token: vm.auth_token,
            cart: {
              cart_items_attributes: [{
                id: vm.cart_items.cart_items[0].id,
                checkin_date: vm.checkinDate,
                checkout_date: vm.checkoutDate
              }]
            }
          })
          .then(response => {
            //console.log(response.data);
            console.log('Change Date');
          })
          .catch(error => {
            if (error.response) {
              console.log(error.response)
            }else{
              console.log('Change Date Error')
            }
          })
      },
      editQuantity: function(num, incre, itemid, i) {
        this.$http.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].getAttribute('content');
        let vm = this;
        vm.isloaded = false;
        var icount = 100;
        var quant = parseInt(num) + parseInt(incre);
        this.confirmRemove = false;
        if (quant == 0) {
          this.$http
            .put("/cart.json", { 
              authenticity_token: vm.auth_token,
              cart: {
                cart_items_attributes: [{
                  id: itemid,
                  _destroy: '1'
                }]
              }
            })
            .then(response => {
              //console.log(response);
              var res = JSON.parse(response.request.response);
              var dat = JSON.parse(res.data);
              vm.latest_cart = dat;
              vm.isloaded = true;
              console.log('Removed item');
            })
            .catch(error => {
              vm.isloaded = true;
              if (error.response) {
                console.log(error.response)
              }else{
                console.log('Remove Item Error')
              }
            })
        } else {
          if (vm.latest_cart.cart_items[i].appointment) {
            icount = vm.latest_cart.cart_items[i].appointment.appointment_slot.available_slot;
          } else if (vm.latest_cart.cart_items[i].itemable.available_unit) {
            icount = vm.latest_cart.cart_items[i].itemable.available_unit;
          } else {
            icount = vm.latest_cart.cart_items[i].itemable.inventory_count;
          }
          if (i != null && quant > icount && incre == 1) {
            alert('You have reach the maximum inventory count.')
            vm.isloaded = true;
          } else {
            this.$http
              .put("/cart.json", { 
                authenticity_token: vm.auth_token,
                cart: {
                  cart_items_attributes: [{
                    id: itemid,
                    quantity: quant
                  }]
                }
              })
              .then(response => {
                var res = JSON.parse(response.request.response);
                var dat = JSON.parse(res.data);
                //console.log(dat)
                vm.latest_cart = dat;
                vm.isloaded = true;
                console.log('Modified quantity');
              })
              .catch(error => {
                vm.isloaded = true;
                if (error.response) {
                  console.log(error.response)
                }else{ 
                  console.log('Modify Quantity Error')
                }
              })
          }
        }
      }
    },
    mounted() {
      let vhe = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vhe}px`);

      this.d_time = this.ava_times[0];
      this.d_day = this.ava_days[0].value;

      //console.log(this.cart_items)
      //console.log(this.cart_store)
      //console.log(this.cart) 
      //console.log(this.current_user)
      //console.log(this.selected_address)
      //console.log(this.redeem_limit)
      //console.log(this.errors)
      //console.log(this.taxes)
      //console.log(this.delivery_rate)
      //console.log(this.delivery_distance)
      //console.log(this.free_delivery_distance)
      //console.log(this.free_delivery_amount)
    }
  }
</script>

<style scoped>

@media (min-width: 768px) {
  .cart-div {
    padding-top: 75px;
    padding-bottom: 30px;
    background: white;
  }
}

@media (max-width: 767px) {
  .cart-div {
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: auto;
    padding-bottom: 70px;
    background: white;
  }
}

</style>