<template>
<div>
    <div class="top-heading mb-5"><a href="/">
        <svg width="34" height="8" viewBox="0 0 34 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35356L3.82843 7.53554C4.02369 7.7308 4.34027 7.7308 4.53553 7.53554C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17158C4.7308 0.976313 4.7308 0.659731 4.53553 0.464469C4.34027 0.269207 4.02369 0.269207 3.82843 0.464469L0.646447 3.64645ZM34 3.5L1 3.5L1 4.5L34 4.5L34 3.5Z" fill="#BB935A"/>
        </svg>
        </a> QR Pay
    </div>
    <div class="w-100 p-5">
        <div v-if="qrpay.total_amount">
            <p class="text-center">Do you confirm to pay</p>
            <p v-if="qrpay.redeem_points" class="text-center" style="text-decoration: line-through;">RM {{qrpay.total_amount}}</p>
            <h2 class="text-center">RM {{qrpay.payment_amount}}</h2>
            <p class="text-center">to <strong>{{qrpay.store_name}}</strong></p>

            <form v-if="!qrpay.redeem_points" action="/payments/qrpay" method="get" class="pt-4">
                <div class="row align-items-center m-auto" style="font-size:14px;max-width:300px">
                    <div class="col-8">
                        <input type="hidden" name="redeem_points" :value="redeemed_points">
                        <input type="hidden" name="vendor_reference" :value="qrpay.vendor_reference">
                        <input type="hidden" name="store_id" :value="qrpay.store_id">
                        <input type="hidden" name="price_cents" :value="qrpay.total_amount*100">
                        <input type="hidden" name="transaction_reference" :value="qrpay.transaction_reference">
                        you can redeem {{redeemed_points}} points
                    </div>
                    <div class="col-4 p-0">
                        <input class="submit-btn m-0 p-2" style="font-size:12px;" type="submit" value="Redeem">
                    </div>
                </div>
            </form>
            <div v-else class="text-center font-weight-bold pt-4" style="font-size:14px;color:green">
                ✔️ You have redeemed {{qrpay.redeem_points}} points.
            </div>
            <form :action="payment_url" method="post">
                <input type="hidden" name="ord_date" :value="qrpay.date">
                <input type="hidden" name="ord_totalamt" :value="qrpay.payment_amount">
                <input type="hidden" name="ord_gstamt" value="0.00">
                <input type="hidden" name="ord_shipname" :value="qrpay.customer_name">
                <input type="hidden" name="ord_mercref" :value="qrpay.transaction_reference">
                <input type="hidden" name="ord_telephone" :value="qrpay.customer_phone_number">
                <input type="hidden" name="ord_email" :value="qrpay.customer_email">
                <input type="hidden" name="ord_mercID" :value="qrpay.merchant_id">
                <input type="hidden" name="merchant_hashvalue" :value="qrpay.merchant_hashvalue">
                <input type="hidden" name="ord_returnURL" value="https://www.koziapps.com/payments/return">
                <input type="hidden" name="ord_customfield1" :value="payment_details">
                <input type="hidden" name="ord_customfield2" :value="qrpay.vendor_reference">
                <input type="submit" value="Pay with kiplePay" class="submit-btn mt-2">
            </form>
        </div>
        <div v-else>
            <h1 class="text-center">404</h1>
            <p class="text-center">There is an error. Please try again.</p>
        </div>
        <ul v-if="errors.length" style="color:red;margin-top:10px;">
            <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
        </ul>
    </div>
</div>
</template>

<script>
    export default {
        props: ['qrpay', 'current_user', 'errors','redeem_limit', 'payment_url'],
        data() {
            let paymentDetails = {}   ;
            let pt = 0;     
            if (this.qrpay) {
                paymentDetails = {
                "pt": "Q",
                "sid": this.qrpay.store_id,
                "cid": this.qrpay.customer_id,
                "ta": this.qrpay.total_amount
                }

                if (this.current_user) {
                    var rpts = parseFloat(this.current_user.loyalty_points);
                    var max = Math.floor(parseFloat(this.qrpay.total_amount)*parseFloat(this.redeem_limit))*100;

                    if (rpts < 100) {
                        pt = 0;
                    } else if (rpts < max) {
                        pt = Math.floor(rpts/100)*100;
                    } else {
                        pt = max;
                    }
                }
            }
            return {
                payment_details: escape(JSON.stringify(paymentDetails)),
                redeemed_points: pt,
            }
        },
        mounted() {
            //console.log(this.qrpay)
        },
        methods: {
        },
    }
</script>