<template>
    <div class="pop-up-panel" style="margin:0;">
        <div class="closebtn"><span @click="goHome">
                <svg width="34" height="8" viewBox="0 0 34 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35356L3.82843 7.53554C4.02369 7.7308 4.34027 7.7308 4.53553 7.53554C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17158C4.7308 0.976313 4.7308 0.659731 4.53553 0.464469C4.34027 0.269207 4.02369 0.269207 3.82843 0.464469L0.646447 3.64645ZM34 3.5L1 3.5L1 4.5L34 4.5L34 3.5Z" fill="#BB935A"/>
                </svg>
            </span> {{category.name}}</div>
        <div v-if="products" id="filter-row" style="top:70px;left:0;padding-top:10px;">
            <button @click="openNav()" class="explore-filter">Sort by ▾</button>
        </div>
        <div v-if="products" class="search-div" style="padding-top:68px;"><input type="text" class="explore-search" v-model="searchkey" placeholder="Search here your fav here"></div>
        <div style="width:100%;padding:20px 4% 50px;margin:0;">
            <div v-if="products && products.length" class="d-flex flex-wrap" style="margin:-8px -2% 0;">
                <div v-for="(product, index) in sorted_products" :key="index" class="prodcard">
                    <a v-if="product.active && product.inventory_count" :href="'/stores/'+product.store.permalink+'/products/'+product.permalink">
                        <img v-if="product.featured_photo.url" class="prodcard-img" :src="product.featured_photo.url" alt="prod-img">
                        <img v-else src='/images/placeholder.png' alt='prod-img' class="prodcard-img">
                    </a>
                    <div v-else class="position-relative">
                        <div class="position-absolute h-100 w-100 text-center" style="background:rgba(255,255,255,0.5);z-index:2;"></div>
                        <img v-if="product.featured_photo.url" class="prodcard-img" :src="product.featured_photo.url" alt="prod-img">
                        <img v-else src='/images/placeholder.png' alt='prod-img' class="prodcard-img">
                    </div>
                    <div class="prodcard-title">
                        <a v-if="product.active && product.available_unit" :href="'/stores/'+product.store.permalink+'/products/'+product.permalink">{{ product.name }}</a>
                        <div v-else>{{ product.name }}</div>
                    </div>
                    <div class="d-flex flex-wrap">
                        <div v-if="product.subcategories.length" class="prodcard-cate">{{product.subcategories[0].name}}</div>
                        <div v-if="product.inventory_count && product.active" class="prodcard-ava"><div class="dot"></div>Available</div>
                        <div v-else class="prodcard-ava"><div class="xdot"></div>Not available</div>
                    </div>
                    <div v-if="product.sales_price_cents != product.original_price_cents && product.sales_price_cents" class="prodcard-price">
                        <span class="slash-price">{{ product.original_price_currency }} {{ (product.original_price_cents/100).toFixed(2) }}</span> 
                        <span style="color:red;">{{ product.sales_price_currency }} {{ (product.sales_price_cents/100).toFixed(2) }}</span>
                    </div>
                    <div v-else class="prodcard-price">
                        {{ product.original_price_currency }} {{ (product.original_price_cents/100).toFixed(2) }}
                    </div>
                </div>
            </div>
            <div v-else-if="rooms && rooms.length" class="d-flex flex-wrap" style="margin:-8px -2% 0;">
                <div class="slide-choice-div border-0" style="margin:0 -20px 30px;box-shadow: 0px 5px 8px rgba(186, 186, 186, 0.25);">
                    <div class="slide-sect-title">Enter dates to view available rooms</div>
                    <div class="row">
                        <div class="col pr-2 select-one-date room_dates">
                            <md-datepicker v-model="inDate" :md-disabled-dates="disabledcheckinDates" md-immediately>
                                <label>Check In Date</label>
                                <span v-if="inDay" class="date-day">{{days[inDay]}}</span>
                            </md-datepicker>
                        </div>
                    <div class="col pl-2 select-one-date room_dates">
                            <md-datepicker v-model="outDate" :md-disabled-dates="disabledcheckoutDates" md-immediately>
                                <label>Check Out Date</label>
                                <span v-if="outDay" class="date-day">{{days[outDay]}}</span>
                            </md-datepicker>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center"><button class="btn room-search-btn px-4" @click="searchRooms()">
                        <svg width="12" height="12" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.8776 7.78108L7.12494 6.02871C7.04583 5.94961 6.93859 5.90567 6.82609 5.90567H6.53954C7.02473 5.28523 7.31304 4.50483 7.31304 3.65589C7.31304 1.63636 5.67639 0 3.65652 0C1.63664 0 0 1.63636 0 3.65589C0 5.67542 1.63664 7.31179 3.65652 7.31179C4.5056 7.31179 5.28613 7.02353 5.90668 6.53842V6.82492C5.90668 6.93741 5.95063 7.04462 6.02974 7.12372L7.78241 8.87609C7.94765 9.0413 8.21486 9.0413 8.37835 8.87609L8.87584 8.37867C9.04109 8.21346 9.04109 7.94629 8.8776 7.78108ZM3.65652 5.90567C2.41365 5.90567 1.40635 4.9003 1.40635 3.65589C1.40635 2.41324 2.4119 1.40611 3.65652 1.40611C4.89938 1.40611 5.90668 2.41148 5.90668 3.65589C5.90668 4.89854 4.90114 5.90567 3.65652 5.90567Z" fill="#BB935A"/>
                        </svg>
                        <span class="pl-2">Search</span></button>
                    </div>
                </div>
                <div v-if="ava_rooms.length && isloaded">
                    <div v-for="(product, index) in ava_rooms" :key="index" class="prodcard">
                        <a v-if="product.active && product.available_unit" :href="'/stores/'+product.store.permalink+'/rooms/'+product.permalink+'?checkin_date='+inFormat+'&checkout_date='+outFormat">
                            <img v-if="product.featured_photo.url" class="prodcard-img" :src="product.featured_photo.url" alt="prod-img">
                            <img v-else src='/images/placeholder.png' alt='prod-img' class="prodcard-img">
                        </a>
                        <div v-else class="position-relative">
                            <div class="position-absolute h-100 w-100 text-center" style="background:rgba(255,255,255,0.5);z-index:2;"></div>
                            <img v-if="prod.featured_photo.url" class="prodcard-img" :src="prod.featured_photo.url" alt="prod-img">
                            <img v-else src='/images/placeholder.png' alt='prod-img' class="prodcard-img">
                        </div>
                        <div class="prodcard-title">
                            <a v-if="product.active && product.available_unit" :href="'/stores/'+product.store.permalink+'/rooms/'+product.permalink+'?checkin_date='+inFormat+'&checkout_date='+outFormat">{{ product.name }}</a>
                            <div v-else>{{ product.name }}</div>
                        </div>
                        <div class="d-flex flex-wrap">
                            <div v-if="product.subcategories.length" class="prodcard-cate">{{product.subcategories[0].name}}</div>
                            <div v-if="product.active && product.available_unit" class="prodcard-ava"><div class="dot"></div>Available</div>
                            <div v-else class="prodcard-ava"><div class="xdot"></div>Not available</div>
                        </div>
                        <div v-if="product.sales_price_cents != product.original_price_cents && product.sales_price_cents" class="prodcard-price">
                            <span class="slash-price">{{ product.original_price_currency }} {{ (product.original_price_cents/100).toFixed(2) }}</span> 
                            <span style="color:red;">{{ product.sales_price_currency }} {{ (product.sales_price_cents/100).toFixed(2) }}</span>
                        </div>
                        <div v-else class="prodcard-price">
                            {{ product.original_price_currency }} {{ (product.original_price_cents/100).toFixed(2) }}
                        </div>
                    </div>
                </div>
                <div v-else-if="!isloaded" class="text-center py-5 w-100">Loading...</div>
                <div v-else-if="!ava_rooms.length && isloaded && daterooms.length" class="text-center py-5 w-100">No Rooms Found.</div>
            </div>
            <div v-else class="text-center py-5">No Products Found.</div>
        </div>
        <div id="sortByPopUp" class="pop-up-panel">
            <div class="closebtn"><span @click="closeNav()">
                <svg width="34" height="8" viewBox="0 0 34 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35356L3.82843 7.53554C4.02369 7.7308 4.34027 7.7308 4.53553 7.53554C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17158C4.7308 0.976313 4.7308 0.659731 4.53553 0.464469C4.34027 0.269207 4.02369 0.269207 3.82843 0.464469L0.646447 3.64645ZM34 3.5L1 3.5L1 4.5L34 4.5L34 3.5Z" fill="#BB935A"/>
                </svg>
            </span> Sort by</div>
            <div id="sort-choices">
                <label class="sort-choice">Alphabetical Order (A-Z)<input class="ml-auto" type="radio" v-model="sort" value="a-z"></label>
                <label class="sort-choice">Alphabetical Order (Z-A)<input class="ml-auto" type="radio" v-model="sort" value="z-a"></label>
                <label class="sort-choice">Recommended<input class="ml-auto" type="radio" v-model="sort" value="recommend"></label>
                <label class="sort-choice">Popularity<input class="ml-auto" type="radio" v-model="sort" value="popular"></label>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'category-page',
        props:['category', 'products', 'rooms'],
        data() {
            return {
                rows: [],
                sort: 'a-z',
                searchkey: null,
                days: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
                inDate: null,
                inDay: null,
                inFormat: null,
                outDate: null,
                outDay: null,
                outFormat: null,
                disabledcheckinDates: date => {
                    return date.getTime() < (Date.now() - 86400000);
                },
                disabledcheckoutDates: date => {
                    return date.getTime() < Date.now();
                },
                isloaded: true,
                daterooms: [],
            }
        },
        watch: { 
            inDate(val) {
                this.inDay = val.getDay();
                this.inFormat = this.formatDate(val);
                this.disabledcheckoutDates = date => {
                    return date.getTime() < (Number(val) + 86400000);
                };
            },
            outDate(val) {
                this.outDay = val.getDay();
                this.outFormat = this.formatDate(val);
                this.disabledcheckinDates = date => {
                    return date.getTime() > (Number(val) - 86400000) || date.getTime() < (Date.now() - 86400000);
                };
            },
        },
        computed: {
            sorted_products() {
                let vm = this;
                var show = vm.products.filter(function(u) {
                    var key = true;
                    if (vm.searchkey) {
                        var search = vm.searchkey.toLowerCase();
                        var target = u.name.toLowerCase();
                        key = target.includes(search);
                    } else {
                        key = true;
                    }
                    return key
                })
                switch (vm.sort) {
                    case 'a-z':
                        show = show.sort((a, b) => a.name.localeCompare(b.name));
                        break;
                    case 'z-a':
                        show = show.sort((a, b) => b.name.localeCompare(a.name));
                        break;
                    default:
                        show = show.sort((a, b) => a.name.localeCompare(b.name));
                }
                return show
            },
            ava_rooms: function() {
                let vm = this;
                var filtered = [];
                filtered = vm.rooms.filter(function(u) {
                    for (var i = 0; i < vm.daterooms.length; i++) {
                        return u.id == vm.daterooms[i].id
                    }
                })
                return filtered;
            }
        },
        methods: {
            goHome: function() {
                window.location.href = '/';
            },
            openNav: function() {
                document.getElementById("sortByPopUp").style.marginLeft = "0";
            },
            closeNav: function() {
                document.getElementById("sortByPopUp").style.marginLeft = "-100%";
            },
            formatDate: function(d) {
                var month = '' + (d.getMonth() + 1);
                var day = '' + d.getDate();
                var year = d.getFullYear();

                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;

                return [year, month, day].join('-');
            },
            searchRooms: function(gin, gout) {
                let vm = this;
                var cins = gin || vm.inFormat;
                var couts = gout || vm.outFormat;
                if (!cins || !couts) {
                    alert('Please choose both check in and check out dates.')
                } else {
                    vm.isloaded = false;
                    this.$http
                        .get("/stores/regatta-suites/available_rooms?checkin_date="+cins+"&checkout_date="+couts)
                        .then(response => {
                            var res = response.data.data;
                            vm.daterooms = res;
                            vm.isloaded = true;
                        })
                        .catch(error => {
                            vm.isloaded = true;
                        });
                }
            },
        },
        mounted() {
            //console.log(this.category)
            //console.log(this.products)
            //console.log(this.rooms)
        }
    }
</script>