<template>
<div>
    <div v-if="!current_user" class="dashboard-div">
        <div class="head-bar d-flex align-items-center justify-content-center">
            <div class="user-name">Please Login to View</div>
        </div>
        <div class="p-5">
            <button class="submit-btn" @click="logDialog = true;regLoginTab = 'login-tab'">Login</button>
                <div class="text-center font-weight-bold py-4 weak-words" style="font-size:16px">OR</div>
            <button class="submit-btn" @click="logDialog = true;regLoginTab = 'register-tab'">Register</button>
        </div>

        <div class="p-4 text-center dashboard-text">
            We’ll never spam you or share your personal information. By creating an account, you agree to Kozi’s 
            <a href="/terms_and_conditions">Terms &amp; Conditions</a> and <a href="/privacy_policy">Privacy Policy</a>.<br><br>
            You can view all the policy’s included <a href="/shipping_policy">Shipping Policy</a> &amp; 
            <a href="/refund_policy">Refund Policy</a>. Please <a href="/contact_us">contact us</a> via our <a href="/help_center">help center</a> if you have any other questions.
        </div>

        <md-dialog :md-active.sync="logDialog">
            <button class="sign-in-xbtn" @click="logDialog = false">✕</button>
            <div id="login-dialog">
                <md-tabs :md-active-tab="regLoginTab" md-alignment="fixed">
                    <md-tab id="register-tab" md-label="Register">
                        <reg-form :authToken="auth_token"></reg-form>
                    </md-tab> 

                    <md-tab id="login-tab" md-label="Login">
                        <login-form :authToken="auth_token"></login-form>
                    </md-tab>
                </md-tabs>
            </div> 
        </md-dialog>
    </div>

    <div v-if="current_user" class="dashboard-div">
        <div class="head-bar d-flex align-items-center">
            <img src='/images/img-place.png' class="user-img">
            <div class="user-name">
                <span v-if="details[0].value">{{details[0].value}}</span>
                <span v-if="details[1].value">{{details[1].value}}</span>
                <span v-if="!details[0].value && !details[1].value">Name not set</span>
            </div>
            <div class="user-point ml-auto"><span class="user-point-num">{{ current_user.loyalty_points }}</span> points</div>
        </div>
        <div id="dashboard-tabs">
            <md-tabs :md-active-tab="dashTabs" md-alignment="fixed">
                <md-tab id="activeorders" md-label="Order" class="p-0">
                    <div v-if="isloaded && !error" style="max-height:calc(100vh - 232px);overflow-y:auto;">
                        <div v-for="(order, index) in current_orders" :key="index" class="border-bottom order-section" style="cursor:pointer;" @click="goOrder(order.id)">
                            <div class="d-flex pb-3 align-items-start">
                                <div class="d-flex flex-wrap align-items-center">
                                    <div class="strong-words mr-2">{{ order.store_name }}</div>
                                    <div v-if="order.status.toLowerCase() == 'expired' || order.status.toLowerCase() == 'refund' || order.status.toLowerCase() == 'cancelled'" class="order-status my-2" style="background:#FF6464;color:white;">{{ order.status }}</div>
                                    <div v-else-if="order.status.toLowerCase() == 'pending'" class="order-status my-2" style="background:#FFD464;color:#262626;">{{ order.status }}</div>
                                    <div v-else class="order-status my-2">{{ order.status }}</div>
                                </div>
                                <div class="ml-auto change-details-btn mt-0 mt-2">view details 
                                    <svg width="17" height="8" viewBox="0 0 17 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z" fill="#BB935A"/>
                                    </svg>
                                </div>
                            </div>
                            <div>Order ID: {{ order.order_number }} ({{ order.order_items_count }} item)</div>
                            <div class="d-flex py-3 align-items-end flex-wrap">
                                <div style="font-size:12px;">Total (incl. tax)</div>
                                <div class="ml-3 strong-words mr-auto" style="line-height:1;">{{ order.total_price_currency }} {{ (order.total_price_cents/100).toFixed(2) }}</div>
                                <div class="weak-words pt-2">{{ formatDatetime(order.created_at) }}</div>
                            </div>
                        </div>
                        <div v-if="!current_orders.length" class="text-center py-5">No current orders.</div>
                    </div>
                    <div v-else-if="!isloaded && !error" class="text-center py-5">Loading</div>
                    <div v-else-if="isloaded && error" class="text-center py-5">There is an error. Please try again.</div>
                </md-tab>
                <md-tab id="profile" md-label="Profile">
                    <div id="dashboard-rows">
                        <div @click="openPro1()" class="dashboard-row">Membership<span class="ml-auto">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                            </svg></span>
                        </div>
                        <div @click="openPro2()" class="dashboard-row">Points History<span class="ml-auto">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                            </svg></span>
                        </div>
                        <a href="/orders/history" class="dashboard-row">Order History<span class="ml-auto">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                            </svg></span>
                        </a>
                        <div @click="openPro3()" class="dashboard-row">Profile Details<span class="ml-auto">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                            </svg></span>
                        </div>
                        <a href="/help_center" class="dashboard-row">Help Center<span class="ml-auto">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                            </svg></span>
                        </a>
                        <div @click="confirmLogout = true" class="dashboard-row">Log Out<span class="ml-auto">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                            </svg></span>
                        </div>
                    </div>
                </md-tab>
            </md-tabs>
            <md-dialog :md-active.sync="confirmLogout">
                <button class="sign-in-xbtn" @click="confirmLogout = false">✕</button>
                <md-dialog-title style="text-align:center;color:#BB935A;font-size:18px;">Confirm to Log Out?</md-dialog-title>
                <div style="padding:0 32px 15px;">
                    <button @click="proceedLogout" class="submit-btn">Logout</button>
                    <button @click="confirmLogout = false;" class="submit-btn-reverse">Cancel</button>
                </div>
            </md-dialog>
        </div>
        <!-- Open Tab Membership -->
        <div id="pro1PopUp" class="pop-up-panel">
            <div class="closebtn"><span @click="closePro1()">
                <svg width="34" height="8" viewBox="0 0 34 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35356L3.82843 7.53554C4.02369 7.7308 4.34027 7.7308 4.53553 7.53554C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17158C4.7308 0.976313 4.7308 0.659731 4.53553 0.464469C4.34027 0.269207 4.02369 0.269207 3.82843 0.464469L0.646447 3.64645ZM34 3.5L1 3.5L1 4.5L34 4.5L34 3.5Z" fill="#BB935A"/>
                </svg>
            </span>  Membership</div>
            <div class="profile-section">
                <div class="member-points-div">
                    <div class="text-center">You currently have</div>
                    <div class="text-center member-points-num">{{ current_user.loyalty_points }}</div>
                    <div class="text-center member-points-text">KOZI Points</div>
                </div>
                <div>Have a code to redeem points?</div>
                <input class="input-field" name="card number" placeholder="Enter your code" v-model="redeem_code">
                <button v-if="!redeemloaded" class="submit-btn" disabled>Loading...</button>
                <button v-else @click="redeemCode" class="submit-btn">Redeem</button>
            </div>
        </div>
        <!-- Open Tab Loyalty Points History -->
        <div id="pro2PopUp" class="pop-up-panel">
            <div class="closebtn"><span @click="closePro2()">
                <svg width="34" height="8" viewBox="0 0 34 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35356L3.82843 7.53554C4.02369 7.7308 4.34027 7.7308 4.53553 7.53554C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17158C4.7308 0.976313 4.7308 0.659731 4.53553 0.464469C4.34027 0.269207 4.02369 0.269207 3.82843 0.464469L0.646447 3.64645ZM34 3.5L1 3.5L1 4.5L34 4.5L34 3.5Z" fill="#BB935A"/>
                </svg>
            </span>  Loyalty Points History</div>
            <div v-for="(row, index) in loyalty" :key="index" class="border-bottom order-section">
                <div v-if="row.reward_code" class="d-flex align-items-center">
                    <div class="strong-words pr-3">Code: {{ row.reward_code }}</div>
                </div>
                <a v-else-if="row.order_id" :href="'/orders/'+row.order_id" class="d-flex align-items-center">
                    <div class="strong-words pr-3">Order: <span style="color:#ECBC7B">{{ row.order.order_number }}</span></div>
                </a>
                <div class="d-flex py-3 align-items-end">
                    <div class="weak-words">{{ formatDatetime(row.created_at) }}</div>
                    <div class="ml-auto strong-words" style="line-height:1;color:green">+ {{ row.loyalty_points }} pt</div>
                </div>
            </div>
            <div v-if="!loyalty.length" class="text-center py-5">No Loyalty Points History.</div>
        </div>
        <!-- Open Tab Profile Details -->
        <div id="pro3PopUp" class="pop-up-panel">
            <div class="closebtn"><span @click="closePro3()">
                <svg width="34" height="8" viewBox="0 0 34 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35356L3.82843 7.53554C4.02369 7.7308 4.34027 7.7308 4.53553 7.53554C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17158C4.7308 0.976313 4.7308 0.659731 4.53553 0.464469C4.34027 0.269207 4.02369 0.269207 3.82843 0.464469L0.646447 3.64645ZM34 3.5L1 3.5L1 4.5L34 4.5L34 3.5Z" fill="#BB935A"/>
                </svg>
            </span>  Profile Details</div>
            <div class="profile-img-div">
                <img src='/images/img-place.png' class="profile-img">
            </div>
            <div class="mt-5">
                <div v-for="(detail, index) in details" :key="index" class="profile-details-row row border-bottom">
                    <div class="col-4 pr-0">{{detail.name}}</div>
                    <div v-if="detail.edit" class="col-8">
                        <input
                        class="profile-input"
                        v-model="detail.value"
                        @blur="detail.edit = false; updateDetails();"
                        @keyup.enter="detail.edit=false; updateDetails();"
                        v-focus
                        >
                    </div>
                    <div v-else class="col-8 d-flex flex-nowrap">
                        <div class="profile-value"> {{detail.value}} </div>
                        <div class="ml-auto" @click="detail.edit = true;">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.84042 3.24301L1.98458 9.09692L1.60167 10.4027L2.8813 10.0328L8.75657 4.15951L7.84042 3.24301ZM8.8395 2.24426L9.75565 3.1601L10.6096 2.30644C10.6703 2.24571 10.7045 2.16335 10.7045 2.07747C10.7045 1.9916 10.6703 1.90924 10.6096 1.84851L10.1509 1.39059C10.0901 1.32988 10.0077 1.29577 9.92184 1.29577C9.83594 1.29577 9.75355 1.32988 9.6928 1.39059L8.84015 2.24426H8.8395ZM11.0677 0.474742L11.5258 0.932666C11.8294 1.23632 12 1.64811 12 2.07747C12 2.50684 11.8294 2.91863 11.5258 3.22228L3.5616 11.1845L0.827412 11.9746C0.715963 12.0068 0.597932 12.0084 0.48564 11.9794C0.373349 11.9503 0.270914 11.8917 0.18903 11.8096C0.107147 11.7274 0.0488165 11.6248 0.0201294 11.5125C-0.00855772 11.4001 -0.00654988 11.2822 0.0259434 11.1709L0.834539 8.41489L8.77795 0.474094C9.0817 0.170532 9.49363 0 9.92314 0C10.3526 0 10.7646 0.170532 11.0683 0.474094L11.0677 0.474742Z" fill="#ECBC7B"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="profile-details-row row border-bottom">
                    <div class="col-4 pr-0">Password</div>
                    <div class="col-8 d-flex flex-nowrap">
                        <div class="profile-value"> **************** </div>
                        <div class="ml-auto" @click="editPassword = true;">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.84042 3.24301L1.98458 9.09692L1.60167 10.4027L2.8813 10.0328L8.75657 4.15951L7.84042 3.24301ZM8.8395 2.24426L9.75565 3.1601L10.6096 2.30644C10.6703 2.24571 10.7045 2.16335 10.7045 2.07747C10.7045 1.9916 10.6703 1.90924 10.6096 1.84851L10.1509 1.39059C10.0901 1.32988 10.0077 1.29577 9.92184 1.29577C9.83594 1.29577 9.75355 1.32988 9.6928 1.39059L8.84015 2.24426H8.8395ZM11.0677 0.474742L11.5258 0.932666C11.8294 1.23632 12 1.64811 12 2.07747C12 2.50684 11.8294 2.91863 11.5258 3.22228L3.5616 11.1845L0.827412 11.9746C0.715963 12.0068 0.597932 12.0084 0.48564 11.9794C0.373349 11.9503 0.270914 11.8917 0.18903 11.8096C0.107147 11.7274 0.0488165 11.6248 0.0201294 11.5125C-0.00855772 11.4001 -0.00654988 11.2822 0.0259434 11.1709L0.834539 8.41489L8.77795 0.474094C9.0817 0.170532 9.49363 0 9.92314 0C10.3526 0 10.7646 0.170532 11.0683 0.474094L11.0677 0.474742Z" fill="#ECBC7B"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="profile-details-row row border-bottom">
                    <div class="col-4 pr-0">Address</div>
                    <div class="col-8 d-flex flex-nowrap">
                        <div v-if="address" class="pr-3" style="color: #373737;">{{address.street1}}</div>
                        <div v-else class="pr-3" style="color: #373737;">Set your Default Address</div>
                        <div class="ml-auto">
                            <a href="/addresses">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.84042 3.24301L1.98458 9.09692L1.60167 10.4027L2.8813 10.0328L8.75657 4.15951L7.84042 3.24301ZM8.8395 2.24426L9.75565 3.1601L10.6096 2.30644C10.6703 2.24571 10.7045 2.16335 10.7045 2.07747C10.7045 1.9916 10.6703 1.90924 10.6096 1.84851L10.1509 1.39059C10.0901 1.32988 10.0077 1.29577 9.92184 1.29577C9.83594 1.29577 9.75355 1.32988 9.6928 1.39059L8.84015 2.24426H8.8395ZM11.0677 0.474742L11.5258 0.932666C11.8294 1.23632 12 1.64811 12 2.07747C12 2.50684 11.8294 2.91863 11.5258 3.22228L3.5616 11.1845L0.827412 11.9746C0.715963 12.0068 0.597932 12.0084 0.48564 11.9794C0.373349 11.9503 0.270914 11.8917 0.18903 11.8096C0.107147 11.7274 0.0488165 11.6248 0.0201294 11.5125C-0.00855772 11.4001 -0.00654988 11.2822 0.0259434 11.1709L0.834539 8.41489L8.77795 0.474094C9.0817 0.170532 9.49363 0 9.92314 0C10.3526 0 10.7646 0.170532 11.0683 0.474094L11.0677 0.474742Z" fill="#ECBC7B"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <md-dialog :md-active.sync="editPassword">
            <button class="sign-in-xbtn" @click="editPassword = false">✕</button>
            <md-dialog-title style="font-size:16px;padding:20px 32px;margin:0;">Change Password</md-dialog-title>
            <div style="padding:0 32px 15px;">
                <input type="password" class="input-field" v-model="password.current" name="customer[current_password]" placeholder="Current Password">
                <input type="password" class="input-field" v-model="password.new" name="customer[password]" placeholder="New Password">
                <input type="password" class="input-field" v-model="password.confirm" name="customer[password_confirmation]" placeholder="Confirm Password">
                <div style="color:#FF4D4D;font-size:12px;white-space:pre;">{{errorlog}}</div>
                <button @click="updatePassword" class="submit-btn">{{update_status}}</button>
            </div>
        </md-dialog>
    </div>
</div>
</template>

<script>
import RegForm from './regForm.vue';
import LoginForm from './loginForm.vue';

    export default {
        components: {
            RegForm,
            LoginForm,
        }, 
        props:['auth_token', 'current_user', 'user_addresses'],
        data() {
            var adr = null;
            if (this.user_addresses.length) {
                adr = this.user_addresses.filter(function(u) {
                    return u.is_default
                })
                adr = adr[0];
            }

            var det;
            if (this.current_user) {
                det = [
                    { name: 'First Name', value: this.current_user.first_name, edit: false },
                    { name: 'Last Name', value: this.current_user.last_name, edit: false },
                    { name: 'Phone number', value: this.current_user.phone_number, edit: false },
                    { name: 'Email', value: this.current_user.email, edit: false },
                ]
            }

            var hash = 'activeorders';
            if(window.location.hash) {
                hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
                console.log(hash);
            }
            
            return {
                details: det,
                password: {current: '', new: '', confirm: ''},
                editPassword: false,
                address: adr,
                editedDetail: null,
                message: 'Hello Vue.js!',
                section: '',
                confirmLogout: false,
                logDialog: false,
                regLoginTab: 'register-tab',
                dashTabs: hash,
                update_status: 'Update',
                errorlog: '',
                isloaded: false,
                error: false,
                current_orders: [],
                history_orders: [],
                redeem_code: null,
                redeemloaded: true,
                loyalty: [],
                loyalty_pagy: [],
            }
        },
        directives: {
            focus: {
                inserted (el) {
                    el.focus()
                }
            }
        },
        methods: {
            openPro1: function() { document.getElementById("pro1PopUp").style.marginLeft = "0"},
            openPro2: function() { document.getElementById("pro2PopUp").style.marginLeft = "0"},
            openPro3: function() { document.getElementById("pro3PopUp").style.marginLeft = "0"},
            closePro1: function() { document.getElementById("pro1PopUp").style.marginLeft = "-100%"},
            closePro2: function() { document.getElementById("pro2PopUp").style.marginLeft = "-100%"},
            closePro3: function() { document.getElementById("pro3PopUp").style.marginLeft = "-100%"},
            orderDetails: function() { document.getElementById("orderPopup").style.marginLeft = "0"},
            goOrder: function(id) {
                window.location.href = '/orders/' + id;
            },
            proceedLogout: function(e) {
                let vm = this;
                this.$http
					.get("/sign_out")
					.then(response => {
						document.location.href="/";
					})
					.catch(error => {
						//console.log(error);
					});
                e.preventDefault();
            },
            updateDetails: function() {
                let vm = this;
                this.$http
                    .put("/profile", { 
                        authenticity_token: vm.auth_token,
                        user: {
                            first_name: vm.details[0].value,
                            last_name: vm.details[1].value,
                            phone_number: vm.details[2].value,
                            email: vm.details[3].value,
                        },
                    })
					.then(response => {
						//console.log(response.data);
                        //console.log('updated')
					})
					.catch(error => {
                        if (error.response) {
                            //console.log(error.response)
                        }else{
                            //console.log('Update Error')
                        }
					});
            },
            updatePassword: function() {
                let vm = this;
                if (vm.password.new.length <= 6) {
                    vm.errorlog = 'Password must be more than 6 digits';
                } else if (vm.password.new != vm.password.confirm) {
                    vm.errorlog = 'Passwords does not match';
                } else {
                    vm.errorlog = '';
                    vm.update_status = 'Updating';
                    this.$http
                        .put("/", { 
                            authenticity_token: vm.authToken,
                            customer: {
                                current_password: vm.password.current,
                                password: vm.password.new,
                                password_confirmation: vm.password.confirm,
                            },
                        })
                        .then(response => {
                            //console.log(response.data);
                            //console.log('updated')
                            vm.update_status = 'Updated';
                            vm.password.current = '';
                            vm.password.new = '';
                            vm.password.confirm = '';
                            setTimeout(function(){ vm.editPassword = false }, 1000);
                        })
                        .catch(error => {
                            if (error.response) {
                                //console.log(error.response)
                            }else{
                                //console.log('Update Error')
                            }
                        });
                }
            },
            parseDate: function (str) {
                if (str) {
                    var d = new Date(str.split('T')[0]);
                    var ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
                    var mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
                    var da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
                    var date = mo + ' ' + da + ', ' + ye;
                    return date;
                } else {
                    return '';
                }
            },
            parseTime: function (str) {
                if (str) {
                    var res = str.split(":");
                    var ap = 'AM';
                    if (parseInt(res[0]) > 12) {
                        res[0] = parseInt(res[0]) - 12;
                        if (res[0] < 10) { res[0] = '0' + res[0] }
                        ap = 'PM';
                    }
                    var time = res[0] + '.' + res[1] + ap;
                    return time;
                } else {
                    return '';
                }
            },
            formatDatetime: function (val) {
                var result = '';
                if (val) {
                    result = this.parseDate(val) + ' ' + this.parseTime(val.split('T')[1]);
                }
                return result;
            },
            redeemCode: function() {
                let vm = this;
                vm.redeemloaded = false;
                this.$http
                    .post("/loyalty_points/redeem", {
                        authenticity_token: vm.auth_token,
                        loyalty_point_code: {
                            reward_code: vm.redeem_code
                        }
                    })
                    .then(response => {
                        alert('Successfully redeemed')
                        window.location.reload();
                    })
                    .catch(error => {
                        if (error.response) { 
                            alert(error.response.data.error)
                        } else {
                            console.log('Add point Error')
                        }
                        vm.redeemloaded = true;
                    })
            },
            getLoyalty: function() {
                let vm = this;
                this.$http
                    .get("/loyalty_points", { 
                        authenticity_token: vm.auth_token,
                    })
					.then(response => {
                        vm.loyalty = response.data.data;
                        vm.loyalty_pagy = response.data.pagy;
                        console.log(rows)
					})
					.catch(error => {
                        console.log(error)
					});
            },
        },
        mounted() {
            let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
            this.$http.defaults.headers.common['X-CSRF-Token'] = token
            //console.log(this.current_user)
            //console.log(this.user_addresses)
            
            this.getLoyalty();

            this.$http
            .get('/orders.json', {
                authenticity_token: this.auth_token,
            })
            .then(response => {
                this.current_orders = response.data.data;
                //console.log(response.data.data)
                this.isloaded = true;
            })
            .catch(error => {
                //console.log(error);
                this.isloaded = true;
                this.error = true;
            });
        }
    }
</script>