<template>
    <div class="pop-up-panel d-flex flex-column m-0">
        <div class="closebtn text-capitalize"><span><a href="/dashboard">
            <svg width="34" height="8" viewBox="0 0 34 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35356L3.82843 7.53554C4.02369 7.7308 4.34027 7.7308 4.53553 7.53554C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17158C4.7308 0.976313 4.7308 0.659731 4.53553 0.464469C4.34027 0.269207 4.02369 0.269207 3.82843 0.464469L0.646447 3.64645ZM34 3.5L1 3.5L1 4.5L34 4.5L34 3.5Z" fill="#BB935A"/>
            </svg></a>
        </span> Order {{order.status.toLowerCase()}}</div>
        <div style="padding-top:30px;">
            <div class="text-center" style="color:#373737;font-weight:bold;">Order ID: {{ order.order_number }}</div>
            <div class="text-center weak-words">{{ created_date }} {{ created_time }}</div>
        </div>
        <div v-if="order.delivery_time" class="profile-section pr-3 border-bottom">
            <div v-if="order.status.toLowerCase() == 'completed'" class="weak-words">Order Received</div>
            <div v-else class="weak-words">Estimated Delivery Time</div>
            <div class="d-flex align-items-center">
                <div class="strong-words">{{ delivery_date }}</div>
                <div class="ml-auto strong-words">{{ delivery_time }}</div>
            </div>
        </div>

        <div v-if="order.delivery_type == 'STAY'" class="profile-section py-3 border-bottom">
            <div class="slide-sect-title m-0 pb-4">Your Stay Period</div>
            <div class="w-100 hotel-datepicker room_dates no_changing">
                <md-datepicker v-model="checkinDate">
                <label>Check in Date</label>
                <span v-if="checkinDay != null" class="date-day">{{checkinDay}}</span>
                </md-datepicker>
            </div>
            <div class="w-100 hotel-datepicker room_dates no_changing">
                <md-datepicker v-model="checkoutDate">
                <label>Check out Date</label>
                <span v-if="checkoutDay != null" class="date-day">{{checkoutDay}}</span>
                </md-datepicker>
            </div>
            <div class="slide-sect-title m-0 py-4">Guest Information</div>
            <div v-if="guest_info.length">
                <div class="d-flex mb-2"><div class="col-6">Title:</div><div><b>{{ guest_info[0] }}</b></div></div>
                <div class="d-flex mb-2"><div class="col-6">First name:</div><div><b>{{ guest_info[1] }}</b></div></div>
                <div class="d-flex mb-2"><div class="col-6">Last name:</div><div><b>{{ guest_info[2] }}</b></div></div>
                <div class="d-flex mb-2"><div class="col-6">Email:</div><div><b>{{ guest_info[3] }}</b></div></div>
                <div class="d-flex mb-2"><div class="col-6">Phone number:</div><div><b>{{ guest_info[4] }}</b></div></div>
                <div v-if="guest_remark" class="d-flex mb-2"><div class="col-6">Remark:</div><div><b>{{ guest_remark }}</b></div></div>
            </div>
        </div>
        <div v-else class="profile-section py-3 border-bottom">
            <div class="slide-sect-title m-0 pb-4">Ordered by</div>
            <div class="d-flex mb-2"><div class="col-4">Name:</div><div><b>{{ customer.first_name }} {{ customer.last_name }}</b></div></div>
            <div class="d-flex mb-2"><div class="col-4">Email:</div><div><b>{{ customer.email }}</b></div></div>
            <div class="d-flex mb-2"><div class="col-4">Phone:</div><div><b>{{ customer.phone_number }}</b></div></div>
        </div>

        <div class="profile-section pb-3 border-bottom">
            <div v-if="times[2].time == 0 && times[3].time == 0"></div>
            <div v-else-if="order.expired_at && order.status.toLowerCase() == 'pending'" style="color:red;margin-bottom:10px;">Expired in: {{ times[2].time }}:{{ times[3].time }}. Please make the payment.</div>
            <div class="weak-words">Status</div>
            <ul v-if="order.delivery_type == 'STAY'" class="status-bar" :style="cssVars">
                <li>
                    <time id="confirm"></time>
                    <div v-if="order.status.toLowerCase() == 'pending'" class="confirm"><strong>Pending</strong> <span class="weak-words">Your order is pending payment</span></div>
                    <div v-else-if="order.status.toLowerCase() == 'expired'" class="confirm"><strong>Expired</strong> <span class="weak-words">Your order is expired</span></div>
                    <div v-else class="confirm"><strong>Confirmed</strong> <span class="weak-words">Your order is confirmed</span></div>
                </li>
                <li>
                    <time id="deliver"></time>
                    <div v-if="order.status.toLowerCase() == 'delivered' || order.status.toLowerCase() == 'completed'"><strong>Completed</strong> <span class="weak-words">Please stay with us again next time!</span></div>
                    <div v-else class="deliver" style="min-height:55px;"></div>
                </li>
            </ul>
            <ul v-else class="status-bar" :style="cssVars">
                <li>
                    <time id="confirm"></time>
                    <div v-if="order.status.toLowerCase() == 'pending'" class="confirm"><strong>Pending</strong> <span class="weak-words">Your order is pending payment</span></div>
                    <div v-else-if="order.status.toLowerCase() == 'expired'" class="confirm"><strong>Expired</strong> <span class="weak-words">Your order is expired</span></div>
                    <div v-else class="confirm"><strong>Confirmed</strong> <span class="weak-words">Your order is confirmed</span></div>
                </li>
                <li>
                    <time id="prepare"></time>
                    <div v-if="order.status.toLowerCase() == 'preparing'" class="prepare"><strong>Preparing</strong> <span class="weak-words">Your order is preparing</span></div>
                    <div v-else-if="order.status.toLowerCase() == 'prepared' || order.status.toLowerCase() == 'delivering' || order.status.toLowerCase() == 'delivered' || order.status.toLowerCase() == 'completed'" class="prepare"><strong>Prepared</strong> <span class="weak-words">Your order is prepared</span></div>
                    <div v-else class="prepare" style="min-height:55px;"></div>
                </li>
                <li>
                    <time id="deliver"></time>
                    <div v-if="order.status.toLowerCase() == 'delivering'"><strong>Delivering</strong> <span class="weak-words">Your order is delivering to you</span></div>
                    <div v-else-if="order.status.toLowerCase() == 'delivered' || order.status.toLowerCase() == 'completed'"><strong>Delivered</strong> <span class="weak-words">Your order is delivered to you</span></div>
                    <div v-else class="deliver" style="min-height:55px;"></div>
                </li>
            </ul>
        </div>
        <div class="profile-section pb-3">
            <div class="strong-words pb-3">{{order.store.name}}</div>
            <div class="d-flex border-bottom pb-4">
                <div>Total (incl. tax/others)</div>
                <div class="ml-auto strong-words">MYR {{ (order.payment_total_cents/100).toFixed(2) }}</div>
            </div>
            <div v-for="(order, index) in order.order_items" :key="index" class="d-flex justify-content-between mt-3" style="color: #373737;">
                <div>{{order.quantity}}x</div>
                <div class="w-50 text-capitalize">
                    <div>{{order.itemable.name}}</div>
                    <div style="font-size:0.85rem" v-if="order.product_variant_id">• {{order.product_variant.name}}</div>
                    <div style="font-size:0.85rem" v-if="order.addons.length">+{{order.addons.length}} Add-ons</div>
                    <div style="font-size:0.85rem" v-if="order.appointment_slot">• {{order.appointment_slot.date}}</div>
                    <div style="font-size:0.85rem" v-if="order.appointment_slot">• {{parseTime(order.appointment_slot.start_time.split('T')[1])}} - {{parseTime(order.appointment_slot.end_time.split('T')[1])}}</div>
                    <div style="font-size:0.85rem;font-style:italic;" v-if="order.remark && !guest_info.length">{{ order.remark }}</div>
                </div>
                <div>{{ order.price_currency }} {{(order.price_cents/100).toFixed(2)}}</div>
            </div>
            <div class="subtotal-div mt-4 mb-2">
                <div class="d-flex flex-nowrap">
                    <span class="w-50 text-truncate">Subtotal</span>
                    <span class="ml-auto">{{ (order.subtotal_price_cents/100).toFixed(2) }}</span>
                </div>
                <div class="d-flex flex-nowrap">
                    <span class="w-50 text-truncate">Tax/Others ({{ order.tax_rate*100 }}%)</span>
                    <span class="ml-auto">{{ (order.tax_amount_cents/100).toFixed(2) }}</span>
                </div>
                <div v-if="!order.order_items[0].room_bookings.length && !order.order_items[0].appointment" class="d-flex flex-nowrap">
                    <span class="w-50 text-truncate">Delivery Fee</span>
                    <span class="ml-auto">{{ (order.delivery_fee_cents/100).toFixed(2) }}</span>
                </div>
                <div v-if="order.loyalty_point_redeem" class="d-flex flex-nowrap">
                    <span class="w-50 text-truncate" style="color:#BB935A;">Redemeed with points</span>
                    <span class="ml-auto">- {{ (order.loyalty_point_redeem/100).toFixed(2) }}</span>
                </div>
            </div>
            <ul>
                <li v-if="order.loyalty_point_redeem" style="color:#BB935A;font-size:12px;margin-bottom:5px;">You have <span style="color:red;">spent {{ order.loyalty_point_redeem }} points</span> in this order.</li>
                <li v-if="order.loyalty_point_reward" style="color:#BB935A;font-size:12px">You have <span style="color:green;">earned {{ order.loyalty_point_reward }} points</span> in this order.</li>
            </ul>
        </div>
        <div class="cart-btn-div">
            <a href="/contact_us">Need support for your order?</a>
            <div v-if="order.status == 'PENDING' && times[2].time != 0 && times[3].time != 0">
              <form :action="payment_url" method="post">
                <input type="hidden" name="ord_date" :value="order.order_date">
                <input type="hidden" name="ord_totalamt" :value="payment_amount">
                <input type="hidden" name="ord_gstamt" value="0.00">
                <input type="hidden" name="ord_shipname" :value="customer_name">
                <input type="hidden" name="ord_mercref" :value="order.kiplepay.transaction_reference">
                <input type="hidden" name="ord_telephone" :value="customer.phone_number">
                <input type="hidden" name="ord_email" :value="customer.email">
                <input type="hidden" name="ord_delcharges" :value="delivery_fee">
                <input type="hidden" name="ord_mercID" :value="order.kiplepay.merchant_id">
                <input type="hidden" name="merchant_hashvalue" :value="order.kiplepay.merchant_hashvalue">
                <input type="hidden" name="ord_returnURL" value="https://www.koziapps.com/payments/return">
                <input type="hidden" name="ord_customfield1" :value="payment_details">
                <input type="submit" value="Pay with kiplePay" class="submit-btn mt-2">
              </form>
            </div>
            <div v-else>
              <button @click="gotoContact" class="submit-btn mt-2">Contact Us</button>
            </div>
        </div>

        <md-dialog :md-active.sync="rating_status">
            <button class="sign-in-xbtn" @click="rating_status = false">✕</button>
            <div style="padding:0 32px 15px;">
                <div class="text-center text-capitalize">Rate {{order.store.name}}</div>
                <div class="text-center text-uppercase font-weight-bold rate-name py-3">{{order.store.name}}</div>
                <div class="d-flex justify-content-center">
                    <star-rating :show-rating="false" @rating-selected="setRating" :star-size="25" :padding="10"></star-rating>
                </div>
                <button v-if="rateloaded" @click="rateStore" class="submit-btn mt-5">Rate</button>
                <div v-else class="submit-btn mt-5" style="background:#CCCCCC;text-align:center;">loading...</div>
            </div>
        </md-dialog>
    </div>
</template>

<script>
import StarRating from 'vue-star-rating'

    export default {
        name: 'product-slide',
        props: ['order', 'customer', 'point', 'auth_token', 'payment_url'],
        components: {
            StarRating,
        },
        data() {
            var ddate = null;
            var dtime = null;
            var inDate = null;
            var inDay = null;
            var outDate = null;
            var outDay = null;
            var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];

            if (this.order.delivery_time) {
                var d = new Date(this.order.delivery_time.split('T')[0]);
                d.setHours(this.order.delivery_time.split('T')[1].split(':')[0])
                d.setMinutes(this.order.delivery_time.split('T')[1].split(':')[1])

                const early = new Date(d.getTime() - 15*60*1000) // minus 15 minutes
                const late = new Date(d.getTime() + 15*60*1000) // plus 15 minutes

                ddate = this.parseDate(this.order.delivery_time);
                dtime = this.parseTime(early.getHours() + ':' + early.getMinutes()) + ' - ' + this.parseTime(late.getHours() + ':' + late.getMinutes())
            }

            if (this.order.delivery_type == 'STAY') {
                inDate = new Date(this.order.order_items[0].room_bookings[0].date);
                if (inDate) { inDay = days[inDate.getDay()]; }
                outDate = new Date(this.order.order_items[0].room_bookings[this.order.order_items[0].room_bookings.length - 1].date);
                outDate.setDate(outDate.getDate() + 1);
                if (outDate) { outDay = days[outDate.getDay()]; }

                var guest = this.order.order_items[0].remark.split('<Guest Details>')[1].split('<AND>');
                var guest_remark = this.order.order_items[0].remark.split('<Guest Details>')[0];
            }

            const cdate = this.parseDate(this.order.ordered_at);
            const ctime = this.parseTime(this.order.ordered_at.split('T')[1])
            const taxAmount   = (this.order.tax_amount_cents/100).toFixed(2)
            const deliveryFee = (this.order.delivery_fee_cents/100).toFixed(2)
            const totalAmount = (this.order.total_price_cents/100).toFixed(2)
            const paymentAmount = (this.order.payment_total_cents/100).toFixed(2)
            // const paymentAmount = ((this.order.payment_total_cents/100) - (this.order.loyalty_point_redeem)).toFixed(2)
            const customerName = this.customer.last_name

            const paymentDetails = {
              "pt": "O",
              "oid": this.order.id,
              "sid": this.order.store.id,
              "tamt": taxAmount,
              "cid": this.customer.id,
              "ta": totalAmount
            }

            var confc = '#C4C4C4';var prepc = '#C4C4C4';var delic = '#C4C4C4';var d_status = false;
            if (this.order.status.toLowerCase() == 'confirmed' || this.order.status.toLowerCase() == 'preparing') { confc = '#6AD119';}
            else if (this.order.status.toLowerCase() == 'prepared' || this.order.status.toLowerCase() ==  'delivering') { confc = '#6AD119';prepc = '#6AD119';}
            else if (this.order.status.toLowerCase()  == 'delivered' || this.order.status.toLowerCase() ==  'completed') { confc = '#6AD119';prepc = '#6AD119';delic = '#6AD119';}

            if ((this.order.status.toLowerCase()  == 'delivered' || this.order.status.toLowerCase() ==  'completed') && !this.order.rating) {
                d_status = true;
            }
            
            return {
                confColor: confc,
                prepColor: prepc,
                deliColor: delic,
                rating_status: d_status,
                rating: '',
                rateloaded: true,
                delivery_date: ddate,
                delivery_time: dtime,
                created_date: cdate,
                created_time: ctime,
                tax_amount: taxAmount,
                delivery_fee: deliveryFee,
                total_amount: totalAmount,
                payment_amount: paymentAmount,
                customer_name: customerName,
                payment_details: escape(JSON.stringify(paymentDetails)),
                startTime: new Date(),
                endTime: this.order.expired_at,
                times: [
                { id: 0, text: "Days", time: 1 },
                { id: 1, text: "Hours", time: 1 },
                { id: 2, text: "Minutes", time: 1 },
                { id: 3, text: "Seconds", time: 1 }
                ],
                progress: 100,
                timeinterval: undefined,
                guest_info: guest || [],
                guest_remark: guest_remark || "",
                checkinDate: inDate,
                checkinDay: inDay,
                checkoutDate: outDate,
                checkoutDay: outDay,
            }
        },
        mounted() {
            //console.log(this.order)
            //console.log(this.order_store)
            //console.log(this.customer)
        },
        methods: {
            setRating: function(rate) {
                //console.log(rate)
                this.rating = rate;
            },
            rateStore: function() {
                let vm = this;
                vm.rateloaded = false;
                this.$http
                    .put('/orders/' + vm.order.id + '.json', {
                        authenticity_token: vm.auth_token,
                        order: {
                            rating: vm.rating
                        }
                    })
                    .then(response => {
                        console.log('Rate success')
                        vm.rateloaded = true;
                        vm.rating_status = false;
                    })
                    .catch(error => {
                        console.log('Rate error');
                    });
            },
            parseDate: function(str) {
                var d = new Date(str.split('T')[0]);
                var ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
                var mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
                var da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
                var date = mo + ' ' + da + ', ' + ye;

                return date;
            },
            parseTime: function(str) {
                if (str) {
                    var res = str.split(":");
                    var ap = 'AM';
                    if (parseInt(res[0]) == 12) { ap = 'PM'; }
                    else if (parseInt(res[0]) > 12) {
                        res[0] = parseInt(res[0]) - 12;
                        if (res[0] < 10) {res[0] = '0' + res[0]}
                        ap = 'PM';
                    }
                    var time = res[0] + '.' + res[1] + ap;
                    return time;
                } else {
                    return '';
                }
            },
            postPay: function() {
                let vm = this;
                this.$http
                    .post('/orders/' + vm.order.id + '/payment', {
                        authenticity_token: vm.auth_token,
                    })
                    .then(response => {
                        //console.log(response);
                        //console.log('Payment success')
                        window.location.reload();
                    })
                    .catch(error => {
                        if (error.response) {
                            //console.log(error.response)
                        } else {
                            //console.log('Payment error')
                        }
                    });
            },
            gotoContact: function() {
                window.location.href = "/contact_us"
            },
            updateTimer: function() {
                if (
                    this.times[3].time > 0 ||
                    this.times[2].time > 0 ||
                    this.times[1].time > 0 ||
                    this.times[0].time > 0
                ) {
                    this.getTimeRemaining();
                    this.updateProgressBar();
                } else {
                    clearTimeout(this.timeinterval);
                    // this.times[3].time = this.times[2].time = this.times[1].time = this.times[0].time = 0;
                    this.progress = 0;
                }
            },
            getTimeRemaining: function() {
                let t = Date.parse(new Date(this.endTime)) - Date.parse(new Date());
                if(t >= 0){
                this.times[3].time = Math.floor(t / 1000 % 60); //seconds
                this.times[2].time = Math.floor(t / 1000 / 60 % 60); //minutes
                this.times[1].time = Math.floor(t / (1000 * 60 * 60) % 24); //hours
                this.times[0].time = Math.floor(t / (1000 * 60 * 60 * 24)); //days
                }else {
                    this.times[3].time = this.times[2].time = this.times[1].time = this.times[0].time = 0;
                    this.progress = 0;
                }
            },
            updateProgressBar: function() {
                let startTime = Date.parse(new Date(this.startTime));
                let currentTime = Date.parse(new Date());
                let endTime = Date.parse(new Date(this.endTime));
                let interval = parseFloat(
                    (currentTime - startTime) / (endTime - startTime) * 100
                ).toFixed(2);
                this.progress = 100-interval;
            }
        },
        computed: {
            cssVars() {
                return {
                    '--confirm-color': this.confColor,
                    '--prepare-color': this.prepColor,
                    '--deliver-color': this.deliColor,
                }
            }
        },
        created: function() {
            this.updateTimer();
            this.timeinterval = setInterval(this.updateTimer, 1000);
        },
    }
</script>

<style scoped>

    .status-bar {
        width: 100%;
        padding: 10px 10px 0;
        margin: 0;
    }

    .status-bar li {
        display: flex;
    }

    .status-bar time {
        position: relative;
        color: #C4C4C4;
        padding: 0;
    }

    .status-bar #confirm::after {
        content: "";
        position: absolute;
        z-index: 2;
        right: -1px;
        top: 5px;
        transform: translateX(50%);
        border-radius: 50%;
        background: var(--confirm-color, #C4C4C4);
        border: 1px var(--confirm-color, #C4C4C4) solid;
        width: .8em;
        height: .8em;
    }

    .status-bar #prepare::after {
        content: "";
        position: absolute;
        z-index: 2;
        right: -1px;
        top: 5px;
        transform: translateX(50%);
        border-radius: 50%;
        background: var(--prepare-color, #C4C4C4);
        border: 1px var(--prepare-color, #C4C4C4) solid;
        width: .8em;
        height: .8em;
    }

    .status-bar #deliver::after {
        content: "";
        position: absolute;
        z-index: 2;
        right: -1px;
        top: 5px;
        transform: translateX(50%);
        border-radius: 50%;
        background: var(--deliver-color, #C4C4C4);
        border: 1px var(--deliver-color, #C4C4C4) solid;
        width: .8em;
        height: .8em;
    }

    .status-bar div {
        padding: 0 1.3em 1em 1.3em;
        position: relative;
    }

    .status-bar .confirm::before {
        content: "";
        position: absolute;
        z-index: 1;
        left: 0;
        top: 5px;
        height: 100%;
        border-left: 2px var(--confirm-color, #C4C4C4) solid;
    }

    .status-bar .prepare::before {
        content: "";
        position: absolute;
        z-index: 1;
        left: 0;
        top: 5px;
        height: 100%;
        border-left: 2px var(--deliver-color, #C4C4C4) solid;
    }

    .status-bar strong {
        display: block;
        font-weight: bolder;
    }
</style>