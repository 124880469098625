<template>
    <div class="pop-up-panel m-0" id="dashboard-tabs">
        <div class="closebtn"><span @click="goOrder('dashboard')">
                <svg width="34" height="8" viewBox="0 0 34 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35356L3.82843 7.53554C4.02369 7.7308 4.34027 7.7308 4.53553 7.53554C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17158C4.7308 0.976313 4.7308 0.659731 4.53553 0.464469C4.34027 0.269207 4.02369 0.269207 3.82843 0.464469L0.646447 3.64645ZM34 3.5L1 3.5L1 4.5L34 4.5L34 3.5Z"
                        fill="#BB935A" />
                </svg>
            </span> Transaction History
        </div>

        <md-tabs :md-active-tab="dashTabs" md-alignment="fixed">
            <md-tab id="orders" md-label="Orders" class="p-0">
                <div v-for="(order, index) in orders" :key="index" class="border-bottom order-section" style="cursor:pointer;" @click="goOrder(order.id)">
                    <div class="d-flex pb-3 align-items-start">
                        <div class="d-flex flex-wrap align-items-center">
                            <div class="strong-words mr-2">{{ order.store_name }}</div>
                            <div v-if="order.status.toLowerCase() == 'expired' || order.status.toLowerCase() == 'refund' || order.status.toLowerCase() == 'cancelled'" class="order-status my-2" style="background:#FF6464;color:white;">{{ order.status }}</div>
                            <div v-else-if="order.status.toLowerCase() == 'pending'" class="order-status my-2" style="background:#FFD464;color:#262626;">{{ order.status }}</div>
                            <div v-else class="order-status my-2">{{ order.status }}</div>
                        </div>
                        <div class="ml-auto change-details-btn mt-0 mt-2">view details 
                            <svg width="17" height="8" viewBox="0 0 17 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z" fill="#BB935A"/>
                            </svg>
                        </div>
                    </div>
                    <div>Order ID: {{ order.order_number }} ({{ order.order_items_count }} item)</div>
                    <div class="d-flex py-3 align-items-end">
                        <div style="font-size:12px;">Total (incl. tax)</div>
                        <div class="ml-3 strong-words" style="line-height:1;">{{ order.total_price_currency }}
                            {{ (order.total_price_cents/100).toFixed(2) }}</div>
                        <div class="ml-auto weak-words">{{ formatDatetime(order.created_at) }}</div>
                    </div>
                </div>
                <div v-if="!orders.length" class="text-center py-5">No Order History.</div>
            </md-tab>
            <md-tab id="qrpay" md-label="QR Pay" class="p-0">
                <div v-for="(row, index) in rows" :key="index" class="border-bottom order-section" style="cursor:pointer;">
                    <div class="d-flex pb-4 align-items-center">
                        <div class="strong-words" style="max-width:40%;">{{ row.store_id }}</div>
                        <div class="d-flex align-items-center order-status">{{ row.status }}</div>
                    </div>
                    <div class="d-flex py-3 align-items-end">
                        <div style="font-size:12px;">Total (incl. tax)</div>
                        <div class="ml-3 strong-words" style="line-height:1;">{{ row.total_amount_currency }}
                            {{ (row.total_amount_cents/100).toFixed(2) }}</div>
                        <div class="ml-auto weak-words">{{ formatDatetime(row.created_at) }}</div>
                    </div>
                </div>
                <div v-if="!rows.length" class="text-center py-5">No QR Pay History.</div>
            </md-tab>
        </md-tabs>
    </div>
</template>

<script>
    export default {
        props: ['orders', 'auth_token'],
        data() {

            var hash = 'orders';
            if(window.location.hash) {
                hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
            }

            return {
                editedDetail: null,
                message: 'Hello Vue.js!',
                section: '',
                showLogout: false,
                confirmLogout: false,
                logDialog: false,
                errorlog: '',
                isloaded: false,
                error: false,
                dashTabs: hash,
                rows: [],
                pagy: [],
            }
        },
        methods: {
            getQrpay: function() {
                let vm = this;
                this.$http
                    .get("/payments.json", { 
                        authenticity_token: vm.auth_token,
                        },
                    )
					.then(response => {
                        vm.rows = response.data.data;
                        vm.pagy = response.data.pagy;
					})
					.catch(error => {
                        console.log(error)
					});
            },
            goOrder: function (id) {
                if (id == 'dashboard') {
                    window.location.href = '/dashboard#profile';
                } else {
                    window.location.href = '/orders/' + id;
                }
            },
            parseDate: function (str) {
                if (str) {
                    var d = new Date(str.split('T')[0]);
                    var ye = new Intl.DateTimeFormat('en', {
                        year: 'numeric'
                    }).format(d);
                    var mo = new Intl.DateTimeFormat('en', {
                        month: 'short'
                    }).format(d);
                    var da = new Intl.DateTimeFormat('en', {
                        day: '2-digit'
                    }).format(d);
                    var date = mo + ' ' + da + ', ' + ye;
                    return date;
                } else {
                    return '';
                }
            },
            parseTime: function (str) {
                if (str) {
                    var res = str.split(":");
                    var ap = 'AM';
                    if (parseInt(res[0]) > 12) {
                        res[0] = parseInt(res[0]) - 12;
                        if (res[0] < 10) {
                            res[0] = '0' + res[0]
                        }
                        ap = 'PM';
                    }
                    var time = res[0] + '.' + res[1] + ap;
                    return time;
                } else {
                    return '';
                }
            },
            formatDatetime: function (val) {
                var result = '';
                if (val) {
                    result = this.parseDate(val) + ' ' + this.parseTime(val.split('T')[1]);
                }
                return result;
            },
        },
        mounted() {
            this.getQrpay();
        }
    }
</script>