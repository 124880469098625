<template>
    <!-- Open Tab Location -->
    <div id="locPopUp" class="pop-up-panel">
        <div class="closebtn"><span @click="closeLoc()">
            <svg width="34" height="8" viewBox="0 0 34 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35356L3.82843 7.53554C4.02369 7.7308 4.34027 7.7308 4.53553 7.53554C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17158C4.7308 0.976313 4.7308 0.659731 4.53553 0.464469C4.34027 0.269207 4.02369 0.269207 3.82843 0.464469L0.646447 3.64645ZM34 3.5L1 3.5L1 4.5L34 4.5L34 3.5Z" fill="#BB935A"/>
            </svg>
        </span>Address</div>
        <Gmap-Map style="width:100%; height:calc(100% - 80px);" :zoom="16" :center="position" :options="mapOptions">
            <Gmap-Marker v-if="this.pin" :position="position"></Gmap-Marker>
        </Gmap-Map>
        
        <form id="map-input" autocomplete="chrome-off">
            <button @click="getLocation" class="self-loc">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.0915 1.87234L10.0616 0.1451L8.38468 0.174139L8.41459 1.90138C6.71756 2.12075 5.14557 2.91065 3.95675 4.14138C2.76792 5.37211 2.03292 6.9705 1.87244 8.6741L0.145196 8.70401L0.174236 10.3809L1.90148 10.351C2.12085 12.0481 2.91075 13.62 4.14147 14.8089C5.3722 15.9977 6.9706 16.7327 8.6742 16.8932L8.70411 18.6204L10.381 18.5914L10.3511 16.8641C12.0482 16.6448 13.6201 15.8549 14.809 14.6241C15.9978 13.3934 16.7328 11.795 16.8933 10.0914L18.6205 10.0615L18.5915 8.38458L16.8642 8.41449C16.6449 6.71747 15.855 5.14548 14.6242 3.95665C13.3935 2.76782 11.7951 2.03282 10.0915 1.87234ZM9.4845 15.252C6.23963 15.3082 3.56978 12.7293 3.51359 9.4844C3.4574 6.23953 6.03635 3.56968 9.28122 3.51349C12.5261 3.4573 15.1959 6.03626 15.2521 9.28112C15.3083 12.526 12.7294 15.1958 9.4845 15.252Z" fill="#ECBC7B"/>
                    <path d="M9.32478 6.02889C7.47177 6.06098 5.9969 7.58783 6.02899 9.44084C6.06108 11.2939 7.58792 12.7687 9.44094 12.7366C11.294 12.7045 12.7688 11.1777 12.7367 9.32468C12.7046 7.47167 11.1778 5.9968 9.32478 6.02889ZM9.4119 11.0597C8.48958 11.0757 7.7219 10.3341 7.70592 9.4118C7.68995 8.48949 8.43151 7.7218 9.35382 7.70583C10.2761 7.68986 11.0438 8.43141 11.0598 9.35372C11.0758 10.276 10.3342 11.0437 9.4119 11.0597Z" fill="#ECBC7B"/>
                </svg>
                <span class="pl-4">Use your current location</span>
            </button>
            
            <div class="self-loc-address" v-if="inputAddress">
                <div style="width:calc(100% - 30px);" class="text-truncate">{{inputAddress}}</div>
                <button class="self-format-btn" @click="inputAddress = null">✕</button>
            </div>
            <gmap-autocomplete placeholder="Search your address" @place_changed="setPlace"></gmap-autocomplete>
        </form>
        <div style="position:fixed;bottom:30px;width:100%;padding:0 10%;">
            <button v-if="formatted_address" @click="handleSubmit" class="submit-btn">Next</button>
        </div>
        <div v-if="!isloaded" id="cover-spin"></div>
    </div>
</template>

<script>
import axios from 'axios';

    export default {
        props: ['lat', 'lng'],
        data() {
            return {
                GOOGLE_API_KEY: 'AIzaSyBilVCinzoR14Jcd23-GUGTHoqLosoHhqs',
                pin: false,
                mapOptions: {
                    disableDefaultUI : true
                },
                place: null,
                position: {
                    lat: 1.56111,
                    lng: 110.34167
                },
                formatted_address: '',
                address: '',
                state: '',
                postcode: '',
                city: '',
                inputAddress: null,
                isloaded: true,
            }
        },
        methods: {
            getLocationSuccess(position) {
                this.locationError = "";
                this.pin = true;
                this.position.lat = position.coords.latitude;
                this.position.lng = position.coords.longitude;
                this.reverseGeocode(this.position);
            },
            getLocationFail() {
                this.locationError = "Please allow browser to track your location.";
            },
            getLocation(e) {
                e.preventDefault();
                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(
                    this.getLocationSuccess,
                    this.getLocationFail
                    );
                } else {
                    this.locationError = "Your browser not supported this feature.";
                }
            },
            reverseGeocode(coordinates){
                delete axios.defaults.headers.common['X-CSRF-Token'];
                axios
                .get(`https://maps.google.com/maps/api/geocode/json?latlng=${coordinates.lat},${coordinates.lng}&key=${this.GOOGLE_API_KEY}`)
                .then(response => {
                    this.formatted_address = response.data.results[0].formatted_address;
                    this.address = response.data.results[0].address_components;
                    this.inputAddress = response.data.results[0].formatted_address;
                })
                .catch(error => {
                    //console.log(error)
                })
                .finally(() => axios.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].getAttribute('content'))
            },
            setPlace(place) {
                this.pin = true
                this.place = place
                this.position.lat = place.geometry.location.lat();
                this.position.lng = place.geometry.location.lng();
                this.formatted_address = place.formatted_address;
                this.address = place.address_components;
            },
            closeLoc: function () {
                document.getElementById("locPopUp").style.marginLeft = "-100%";
            },
            handleSubmit ($event) {
                this.$emit('sentaddress', $event, this.formatted_address, this.city, this.state, this.postcode, this.position);
                this.isloaded = false;
            }
        },
        watch: {
            address(val) {
                var its_state = val.filter(obj => { return obj.types[0] == 'administrative_area_level_1' });
                this.state = its_state[0].long_name

                var its_postcode = val.filter(obj => { return obj.types[0] == 'postal_code' });
                this.postcode = its_postcode[0].long_name

                var its_city = val.filter(obj => { return obj.types[0] == 'locality' });
                this.city = its_city[0].long_name
            }
        },
    };
</script>

<style lang="scss" scoped>

    .self-format-btn {
        border: none;
        background: none;
        font-weight: bold;
        margin-left: auto;
    }

    #map-input {
        position: absolute;
        top: 90px;
        width: 80%;
        padding: 0;
        left: 50%;
        transform: translate(-50%, 0);
        box-shadow: 0px 2px 10px rgba(95, 95, 95, 0.25);
        -webkit-box-shadow: 0px 2px 10px rgba(95, 95, 95, 0.25);
        -moz-box-shadow: 0px 2px 10px rgba(95, 95, 95, 0.25);
    }

    #map-input .self-loc {
        width: 100%;
        height: 50px;
        padding: 10px 20px;
        margin: 0;
        border: 1px solid #E5E5E5;
        border-radius: 3px 3px 0 0;
        background: white;
        border: 1px solid #E5E5E5;
        border-bottom: none !important;
        color: #848484;
        text-align: left;
        display: flex;
        align-items: center;
    }

    #map-input input {
        width: 100%;
        height: 50px;
        padding: 10px 20px 10px 57px;
        margin: 0;
        border: 1px solid #E5E5E5;
        border-radius: 0 0 3px 3px;
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.8096 12.1039L11.0832 9.37799C10.9602 9.25496 10.7934 9.1866 10.6184 9.1866H10.1726C10.9274 8.22146 11.3758 7.00752 11.3758 5.68694C11.3758 2.54545 8.82994 0 5.68792 0C2.54589 0 0 2.54545 0 5.68694C0 8.82843 2.54589 11.3739 5.68792 11.3739C7.00872 11.3739 8.22287 10.9255 9.18817 10.1709V10.6165C9.18817 10.7915 9.25654 10.9583 9.37959 11.0813L12.106 13.8072C12.363 14.0643 12.7787 14.0643 13.033 13.8072L13.8069 13.0335C14.0639 12.7765 14.0639 12.3609 13.8096 12.1039ZM5.68792 9.1866C3.75457 9.1866 2.18766 7.62269 2.18766 5.68694C2.18766 3.75393 3.75184 2.18729 5.68792 2.18729C7.62126 2.18729 9.18817 3.7512 9.18817 5.68694C9.18817 7.61996 7.624 9.1866 5.68792 9.1866Z' fill='%23BFBABA'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: 25px center;
        background-size: 14px;
    }

    #map-input .self-loc-address {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 50px;
        padding: 10px 20px;
        margin: 0;
        border: 1px solid #E5E5E5;
        border-radius: 0 0 3px 3px;
        display: flex;
        align-items: center;
        background: white;
        color: #373737;
    }

    #map-input input:focus {
        outline-color: #BB935A;
    }
</style>