<template>
    <form @submit.prevent="submitForm" class="form-padding">
        <input class="input-field" name="customer[first_name]" v-model="first_name" placeholder="First name" required>
        <input class="input-field" name="customer[last_name]" v-model="last_name" placeholder="Last name" required>
        <input class="input-field" name="customer[email]" type="email" v-model="email" placeholder="Email" required>
        <input class="input-field" name="customer[phone_number]" v-model="phone" placeholder="Phone" required>
        <input class="input-field" name="customer[password]" type="password" v-model="pass1" placeholder="Password" required>
        <input class="input-field" name="customer[password_confirmation]" type="password" v-model="pass2" placeholder="Confirm password" required>
        <div class="text-capitalize" style="color:#FF4D4D;font-size:12px;white-space:pre;">{{errorlog}}</div>
        <button type="submit" class="submit-btn">Register</button>
        <!-- <div class="form-text">You have an account? <span style="font-weight:bold;color: #BB935A;" @click="regLoginTab='login-tab'">Login here</span></div> -->
    </form>
</template>

<script>
    export default {
        name: 'register-form',
        props: ['authToken'],
        data() {
            return {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                pass1: '',
                pass2: '',
                errorlog: '',
            }
        },
        methods: {
            submitForm: function() {
                let vm = this;
                if (vm.pass1 != vm.pass2) {
                    vm.errorlog = 'Password not match!';
                } else {
                    vm.errorlog = '';
                    this.$http
                        .post("/", { 
                            authenticity_token: vm.authToken,
                            customer: {
                                first_name: vm.first_name,
                                last_name: vm.last_name,
                                email: vm.email,
                                phone_number: vm.phone,
                                password: vm.pass1,
                                password_confirmation: vm.pass2,
                                remember_me: 1,
                            },
                        })
                        .then(response => {
                            //console.log(response.data);
                            window.location.reload();
                        })
                        .catch(error => {
                            if (error.response) {
                                //console.log(error.response.data)
                                const entries = Object.entries(error.response.data)
                                var apiurl = '';
                                for (const [param, value] of entries) {
                                    apiurl = apiurl.concat(`${param} ${value[0]} \n`)
                                }
                                vm.errorlog = apiurl;
                            }else{
                                vm.errorlog = "Login Failed";
                            }
                        });
                }
            } 
        },
        mounted() {
            let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
            this.$http.defaults.headers.common['X-CSRF-Token'] = token
        },
    }
</script>